import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Check, Upload, ArrowRight } from 'react-feather';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import { CompactPicker } from 'react-color';

import { useLocalStorageValue } from '@react-hookz/web'; // cjs

function Cliente({ route }) {


  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [testColor, setTestColor] = useState('red');

  const [isHoveringNextButtonColor, setIsHoveringNextButtonColor] = useState(
    false
  );

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['exhibitionCurrentStep']);

  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  var thisPage = 'Cliente';
  var title = 'Cliente';

  function updateSingleData(field, value) {
    var x = steps;
    x.find((x) => x.name == thisPage).data[field] = value;
    setSteps(x);
    forceUpdate();
  }

  function showError(message) {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function validateThisAndGoToNextPage() {
    try {
      if (
        steps.find((x) => x.name == thisPage).data.ragioneSociale.length < 3
      ) {
        showError('Compila correttamente il campo Ragione Sociale');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Ragione Sociale');
      return;
    }

    try {
      if (steps.find((x) => x.name == thisPage).data.indirizzoVia.length < 2) {
        showError('Compila correttamente il campo Indirizzo');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Indirizzo');
      return;
    }

    try {
      if (
        steps.find((x) => x.name == thisPage).data.indirizzoCivico.length < 1
      ) {
        showError('Compila correttamente il campo N. Civico');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo N. Civico');
      return;
    }

    try {
      if (
        steps.find((x) => x.name == thisPage).data.indirizzoCAP.length < 5 ||
        steps.find((x) => x.name == thisPage).data.indirizzoCAP.length > 5
      ) {
        showError('Compila correttamente il campo CAP');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo CAP');
      return;
    }

    try {
      if (
        steps.find((x) => x.name == thisPage).data.indirizzoProvincia.length < 2
      ) {
        showError('Compila correttamente il campo Provincia');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Provincia');
      return;
    }

    try {
      if (steps.find((x) => x.name == thisPage).data.telefono.length < 6) {
        showError('Compila correttamente il campo Telefono');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Telefono');
      return;
    }

    // url libero

    toast.loading('Validazione...', {
      position: 'bottom-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    setTimeout(() => {
      console.log(steps);

      var index = steps.findIndex((x) => x.name == thisPage);

      var mockArr = steps;
      mockArr[index].validated = true;
      mockArr[index].currentlyOpen = false;
      mockArr[index + 1].currentlyOpen = true;
      setSteps(mockArr);
      setCookie('exhibitionCurrentStep', mockArr[index].id + 1, '/');

      toast.dismiss();

      navigate(Math.random().toString(36).substr(2, 34));
    }, 1000);
  }

  return (
    <div style={{ marginTop: 45, width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <h3>{title}</h3>
      </div>

      <div style={{ marginTop: -15 }}>
        <Row className="mt-4">
          <Col
            sm={3}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Ragione sociale
          </Col>
          <Col sm={9} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Ragione sociale"
              value={
                steps.find((x) => x.name == thisPage).data.ragioneSociale !=
                null
                  ? steps.find((x) => x.name == thisPage).data.ragioneSociale
                  : ''
              }
              onChange={(v) => {
                updateSingleData('ragioneSociale', v.target.value);
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col
            sm={3}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Indirizzo
          </Col>
          <Col sm={4} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Via/Viale/Strada..."
              value={
                steps.find((x) => x.name == thisPage).data.indirizzoVia != null
                  ? steps.find((x) => x.name == thisPage).data.indirizzoVia
                  : ''
              }
              onChange={(v) => {
                updateSingleData('indirizzoVia', v.target.value);
              }}
            />
          </Col>
          <Col sm={1} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="N."
              value={
                steps.find((x) => x.name == thisPage).data.indirizzoCivico !=
                null
                  ? steps.find((x) => x.name == thisPage).data.indirizzoCivico
                  : ''
              }
              onChange={(v) => {
                updateSingleData('indirizzoCivico', v.target.value);
              }}
            />
          </Col>
          <Col sm={2} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="CAP"
              value={
                steps.find((x) => x.name == thisPage).data.indirizzoCAP != null
                  ? steps.find((x) => x.name == thisPage).data.indirizzoCAP
                  : ''
              }
              onChange={(v) => {
                updateSingleData('indirizzoCAP', v.target.value);
              }}
            />
          </Col>
          <Col sm={2} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Prov."
              value={
                steps.find((x) => x.name == thisPage).data.indirizzoProvincia !=
                null
                  ? steps.find((x) => x.name == thisPage).data
                      .indirizzoProvincia
                  : ''
              }
              onChange={(v) => {
                updateSingleData('indirizzoProvincia', v.target.value);
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col
            sm={3}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Telefono
          </Col>
          <Col sm={9} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Telefono"
              value={
                steps.find((x) => x.name == thisPage).data.telefono != null
                  ? steps.find((x) => x.name == thisPage).data.telefono
                  : ''
              }
              onChange={(v) => {
                updateSingleData('telefono', v.target.value);
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col
            sm={3}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Sito Web
          </Col>
          <Col sm={9} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Sito Web"
              value={
                steps.find((x) => x.name == thisPage).data.URL != null
                  ? steps.find((x) => x.name == thisPage).data.URL
                  : ''
              }
              onChange={(v) => {
                updateSingleData('URL', v.target.value);
              }}
            />
          </Col>
        </Row>
      
   
       
      </div>

      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'left' }}>
        <Button
          onMouseOver={() => {
            setIsHoveringNextButtonColor(true);
          }}
          onMouseLeave={() => {
            setIsHoveringNextButtonColor(false);
          }}
          variant="outline-light"
          onClick={() => {
            validateThisAndGoToNextPage();
          }}
        >
          <ArrowRight
            className="svg"
            color={!isHoveringNextButtonColor ? 'white' : 'black'}
            data-type="svg"
            size={30}
          />
        </Button>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Cliente;
