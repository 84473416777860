import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Check, Upload, ArrowRight } from 'react-feather';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import { Checkbox, Radio } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';

import { useLocalStorageValue } from '@react-hookz/web'; // cjs

import { CompactPicker } from 'react-color';

function Tipologia({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [isHoveringNextButtonColor, setIsHoveringNextButtonColor] = useState(
    false
  );

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['exhibitionCurrentStep']);

  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  const [pavimentazioneColor, setPavimentazioneColor] = useState('');

  const [paretiColor, setParetiColor] = useState('');

  var thisPage = 'Tipologia';
  var title = 'Tipologia';

  useEffect(() => {
    var x = document.getElementsByClassName('flexbox-fix');
    for (let item of x) {
      item.style.display = 'none';
    }

    var x = document.getElementsByClassName('compact-picker');

    for (let item of x) {
      var element = item;
      item.style.width = '250px';
      item.style.paddingBottom = '1px';
      item.style.paddingTop = '1px';

      var divs = element.getElementsByTagName('div');
      for (let color of divs) {
        if (color.hasAttribute('title')) {
          color.style.transform = 'scale(1.5)';
          color.style.margin = '10px';
          color.style.borderRadius = '20px';
        }
      }
    }
  }, []);

  function updateSingleData(field, value) {
    var x = steps;
    x.find((x) => x.name == thisPage).data[field] = value;
    setSteps(x);
    forceUpdate();
  }

  function showError(message) {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function validateThisAndGoToNextPage() {
    try {
      if (+steps.find((x) => x.name == thisPage).data.budget < 0.01) {
        showError('Compila correttamente il campo Budget');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Budget');
      return;
    }

    /* try {
      if (+steps.find((x) => x.name == thisPage).data.hMax < 0.01) {
        showError('Compila correttamente il campo H. MAX Consentita');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo H. MAX Consentita');
      return;
    } */

    var fields = ['stand', 'genere', 'livello'];

    var proceed = true;

    try {
      fields.forEach(function (resp) {
        console.log(resp);
        var values = steps.find((x) => x.name == thisPage).data;
        console.log(values);
        var values = values[resp];
        var length = Object.keys(values).length;
        var falseCounter = 0;
        for (const property in values) {
          if (!values[property]) falseCounter++;
        }
        if (falseCounter >= length) {
          throw resp.split('')[0].toUpperCase() + resp.substr(1, resp.length);
        }
      });
    } catch (e) {
      showError("Scegli un'opzione per " + e);
      proceed = false;
    }

    if (!proceed) return;

    // url libero

    toast.loading('Validazione...', {
      position: 'bottom-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    setTimeout(() => {
      console.log(steps);

      var index = steps.findIndex((x) => x.name == thisPage);

      var mockArr = steps;
      mockArr[index].validated = true;
      mockArr[index].currentlyOpen = false;
      mockArr[index + 1].currentlyOpen = true;
      setSteps(mockArr);
      forceUpdate();
      setCookie('exhibitionCurrentStep', mockArr[index].id + 1, '/');

      toast.dismiss();

      navigate(Math.random().toString(36).substr(2, 34));
    }, 1000);
  }

  return (
    <div style={{ marginTop: 45, width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <h3>{title}</h3>
      </div>

      <div style={{ marginTop: -15 }}>
        <Row className="mt-4">
          <Col
            sm={4}
            className="mt-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: 600,
            }}
          >
            Budget in EUR
          </Col>
          <Col sm={8} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="number"
            //  step=".01"
              placeholder="Budget in EUR"
              min="0"
              value={steps.find((x) => x.name == thisPage).data.budget <= 0 ? 0 : steps.find((x) => x.name == thisPage).data.budget > 0 ? steps.find((x) => x.name == thisPage).data.budget.toString() : 0 }
              onChange={(v) => {
                updateSingleData('budget', +v.target.value);
              }}
            />
          </Col>
        </Row>
      </div>

      {/*  <div>
        <Row className="mt-4">
          <Col
            sm={4}
            className="mt-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: 600,
            }}
          >
            H. MAX Consentita
          </Col>
          <Col sm={8} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="number"
              step=".01"
              min="0"
              placeholder="H. MAX Consentita in metri"
              value={steps.find((x) => x.name == thisPage).data.hMax}
              onChange={(v) => {
                console.log(v.target.value);
                updateSingleData('hMax', +v.target.value);
              }}
            />
          </Col>
        </Row>
      </div> */}

      <div style={{ marginTop: 10 }}>
        <Row className="mt-4">
          <Col
            sm={2}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: -4,
              fontWeight: 600,
            }}
          >
            Stand
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 30,
            }}
          >
            <Row>
              <Col sm={12}>
                <Checkbox
                  animation="jelly"
                  color="danger"
                  shape="curve"
                  icon={<Check className="svg" data-type="svg" />}
                  checked={
                    steps.find((x) => x.name == thisPage).data.stand.aperto
                  }
                  onChange={(v) => {
                    var mockArr = steps;


                   var b = mockArr.find(x => x.name == thisPage).data.stand
                   console.log(typeof b)

                   for (const [key, value] of Object.entries(b)) {
                     if(typeof b[key] == "boolean")                       if(typeof b[key] == "boolean")  b[key] = false

                   
                  }
                   
                    


                    mockArr.find((x) => x.name == thisPage).data.stand.aperto =
                      v.target.checked;
                    setSteps(mockArr);
                    forceUpdate();
                  }}
                  style={{ marginBottom: 15 }}
                >
                  aperto
                </Checkbox>
              </Col>
            </Row>
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 40,
            }}
          >
            <Row>
              <Col sm={12}>
                <Checkbox
                  animation="jelly"
                  color="danger"
                  shape="curve"
                  icon={<Check className="svg" data-type="svg" />}
                  checked={
                    steps.find((x) => x.name == thisPage).data.stand.semichiuso
                  }
                  onChange={(v) => {
                    var mockArr = steps;


                    var b = mockArr.find(x => x.name == thisPage).data.stand
                    console.log(typeof b)
 
                    for (const [key, value] of Object.entries(b)) {
                                          if(typeof b[key] == "boolean")  b[key] = false

                   }
                    mockArr.find(
                      (x) => x.name == thisPage
                    ).data.stand.semichiuso = v.target.checked;
                    setSteps(mockArr);
                    forceUpdate();
                  }}
                  style={{ marginBottom: 15 }}
                >
                  semichiuso
                </Checkbox>
              </Col>
              {steps.find((x) => x.name == thisPage).data.stand.semichiuso && (
                <Col sm={12}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Descrizione..."
                    value={
                      steps.find((x) => x.name == thisPage).data.stand
                        .semichiusoText
                    }
                    onChange={(v) => {
                      var mockArr = steps;


                      mockArr.find(
                        (x) => x.name == thisPage
                      ).data.stand.semichiusoText = v.target.value;
                      setSteps(mockArr);
                      forceUpdate();
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 20,
            }}
          >
            <Row>
              <Col sm={12}>
                <Checkbox
                  animation="jelly"
                  color="danger"
                  shape="curve"
                  icon={<Check className="svg" data-type="svg" />}
                  checked={
                    steps.find((x) => x.name == thisPage).data.stand.chiuso
                  }
                  onChange={(v) => {
                    var mockArr = steps;

                    var b = mockArr.find(x => x.name == thisPage).data.stand
                    console.log(typeof b)
 
                    for (const [key, value] of Object.entries(b)) {
                                          if(typeof b[key] == "boolean")  b[key] = false

                   }
                    mockArr.find((x) => x.name == thisPage).data.stand.chiuso =
                      v.target.checked;
                    setSteps(mockArr);
                    forceUpdate();
                  }}
                  style={{ marginBottom: 15 }}
                >
                  chiuso
                </Checkbox>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: 10 }}>
        <Row className="mt-3">
          <Col
            sm={2}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: -4,
              fontWeight: 600,
            }}
          >
            Genere
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 30,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.genere.diProdotto
              }
              onChange={(v) => {
                var mockArr = steps;

                var b = mockArr.find(x => x.name == thisPage).data.genere
                console.log(typeof b)

                for (const [key, value] of Object.entries(b)) {
                                      if(typeof b[key] == "boolean")  b[key] = false

               }
                mockArr.find((x) => x.name == thisPage).data.genere.diProdotto =
                  v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              di prodotto
            </Checkbox>
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 40,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.genere.istituzionale
              }
              onChange={(v) => {
                var mockArr = steps;

                var b = mockArr.find(x => x.name == thisPage).data.genere
                console.log(typeof b)

                for (const [key, value] of Object.entries(b)) {
                                      if(typeof b[key] == "boolean")  b[key] = false

               }
                mockArr.find(
                  (x) => x.name == thisPage
                ).data.genere.istituzionale = v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              istituzionale
            </Checkbox>
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 20,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.genere.dImmagine
              }
              onChange={(v) => {
                var mockArr = steps;

                var b = mockArr.find(x => x.name == thisPage).data.genere
                console.log(typeof b)

                for (const [key, value] of Object.entries(b)) {
                                      if(typeof b[key] == "boolean")  b[key] = false

               }
                mockArr.find((x) => x.name == thisPage).data.genere.dImmagine =
                  v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              d'immagine
            </Checkbox>
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: 10 }}>
        <Row className="mt-4">
          <Col
            sm={2}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: -4,
              fontWeight: 600,
            }}
          >
            Livello
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 30,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.livello.economico
              }
              onChange={(v) => {
                var mockArr = steps;

                var b = mockArr.find(x => x.name == thisPage).data.livello
                console.log(typeof b)

                for (const [key, value] of Object.entries(b)) {
                                      if(typeof b[key] == "boolean")  b[key] = false

               }
                mockArr.find((x) => x.name == thisPage).data.livello.economico =
                  v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              economico
            </Checkbox>
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 20,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.livello.standard
              }
              onChange={(v) => {
                var mockArr = steps;

                var b = mockArr.find(x => x.name == thisPage).data.livello
                console.log(typeof b)

                for (const [key, value] of Object.entries(b)) {
                                      if(typeof b[key] == "boolean")  b[key] = false

               }
                mockArr.find((x) => x.name == thisPage).data.livello.standard =
                  v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              standard
            </Checkbox>
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 20,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={steps.find((x) => x.name == thisPage).data.livello.medio}
              onChange={(v) => {
                var mockArr = steps;

                var b = mockArr.find(x => x.name == thisPage).data.livello
                console.log(typeof b)

                for (const [key, value] of Object.entries(b)) {
                  console.log(key,value)
                                      if(typeof b[key] == "boolean")  b[key] = false

               }
                mockArr.find((x) => x.name == thisPage).data.livello.medio =
                  v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              medio
            </Checkbox>
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 20,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={steps.find((x) => x.name == thisPage).data.livello.alto}
              onChange={(v) => {
                var mockArr = steps;

                var b = mockArr.find(x => x.name == thisPage).data.livello
                console.log(typeof b)

                for (const [key, value] of Object.entries(b)) {
                                      if(typeof b[key] == "boolean")  b[key] = false

               }
                mockArr.find((x) => x.name == thisPage).data.livello.alto =
                  v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              alto
            </Checkbox>
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: 50 }}>
        <Row className="mt-4">
          <Col
            sm={8}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: -2,
              fontWeight: 600,
              marginBottom: 10,
            }}
          >
            Pavimentazione{' '}
            <span
              style={{
                backgroundColor: steps.find((x) => x.name == thisPage).data
                  .pavimentazioneColor,
                height: 30,
                width: 30,
                borderRadius: 50,
                marginLeft: 20,
                marginTop: -3,
              }}
            />
          </Col>
          <Col
            sm={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              fontWeight: 600,
              marginBottom: 15,
              visibility:
                Object.entries(
                  steps.find((x) => x.name == thisPage).data.pavimentazione
                )
                  .slice(0, -1)
                  .filter((x) => !JSON.stringify(x).includes('false')).length >
                0
                  ? 'visible'
                  : 'hidden',
            }}
          >
            <CompactPicker
              style={{ marginLeft: 20 }}
              color={
                steps.find((x) => x.name == thisPage).data.pavimentazioneColor
              }
              onChangeComplete={(v) => {
                var mockArr = steps;
                mockArr.find(
                  (x) => x.name == thisPage
                ).data.pavimentazioneColor = v.hex;
                setSteps(mockArr);
              }}
              colors={[
                'red',
                'blue',
                'yellow',
                'black',
                'green',
                'orange',
                'pink',
              ]}
            />
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 30,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.pavimentazione
                  .moquette
              }
              onChange={(v) => {
                var mockArr = steps;

                var c = mockArr.find((x) => x.name == thisPage).data
                  .pavimentazione;
                for (const prop in c) {
                  console.log(`c.${prop} = ${c[prop]}`);
                  if (!prop.includes('Text')) {
                    c[prop] = false;
                  }
                }

                mockArr.find(
                  (x) => x.name == thisPage
                ).data.pavimentazione.moquette = v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              moquette
            </Checkbox>
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 20,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.pavimentazione
                  .parquet
              }
              onChange={(v) => {
                var mockArr = steps;

                var c = mockArr.find((x) => x.name == thisPage).data
                  .pavimentazione;
                for (const prop in c) {
                  console.log(`c.${prop} = ${c[prop]}`);
                  if (!prop.includes('Text')) {
                    c[prop] = false;
                  }
                }

                mockArr.find(
                  (x) => x.name == thisPage
                ).data.pavimentazione.parquet = v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              parquet
            </Checkbox>
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 20,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.pavimentazione.pedana
              }
              onChange={(v) => {
                var mockArr = steps;

                var c = mockArr.find((x) => x.name == thisPage).data
                  .pavimentazione;
                for (const prop in c) {
                  console.log(`c.${prop} = ${c[prop]}`);
                  if (!prop.includes('Text')) {
                    c[prop] = false;
                  }
                }

                mockArr.find(
                  (x) => x.name == thisPage
                ).data.pavimentazione.pedana = v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              pedana
            </Checkbox>
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 20,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.pavimentazione
                  .alluminioMand
              }
              onChange={(v) => {
                var mockArr = steps;

                var c = mockArr.find((x) => x.name == thisPage).data
                  .pavimentazione;
                for (const prop in c) {
                  console.log(`c.${prop} = ${c[prop]}`);
                  if (!prop.includes('Text')) {
                    c[prop] = false;
                  }
                }

                mockArr.find(
                  (x) => x.name == thisPage
                ).data.pavimentazione.alluminioMand = v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              alluminio mand.
            </Checkbox>
          </Col>
          <Col
            sm={12}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 20,
              marginTop: 5,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.pavimentazione.altro
              }
              onChange={(v) => {
                var mockArr = steps;

                var c = mockArr.find((x) => x.name == thisPage).data
                  .pavimentazione;
                for (const prop in c) {
                  console.log(`c.${prop} = ${c[prop]}`);
                  if (!prop.includes('Text')) {
                    c[prop] = false;
                  }
                }

                mockArr.find(
                  (x) => x.name == thisPage
                ).data.pavimentazione.altro = v.target.checked;

                if (!v.target.checked) {
                  mockArr.find(
                    (x) => x.name == thisPage
                  ).data.pavimentazione.altroText = '';
                }

                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              altro
            </Checkbox>
          </Col>
          {steps.find((x) => x.name == thisPage).data.pavimentazione.altro && (
            <Col sm={12}>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Descrizione..."
                value={
                  steps.find((x) => x.name == thisPage).data.pavimentazione
                    .altroText
                }
                onChange={(v) => {
                  var mockArr = steps;
                  mockArr.find(
                    (x) => x.name == thisPage
                  ).data.pavimentazione.altroText = v.target.value;
                  setSteps(mockArr);
                  forceUpdate();
                }}
              />
            </Col>
          )}
        </Row>
      </div>

      <div style={{ marginTop: 10 }}>
        <Row className="mt-4">
          <Col
            sm={8}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: -2,
              fontWeight: 600,
              marginBottom: 10,
            }}
          >
            Pareti{' '}
            <span
              style={{
                backgroundColor: steps.find((x) => x.name == thisPage).data
                  .paretiColor,
                height: 30,
                width: 30,
                borderRadius: 50,
                marginLeft: 20,
                marginTop: -3,
              }}
            />
          </Col>
          <Col
            sm={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              fontWeight: 600,
              marginBottom: 20,
              visibility:
                Object.entries(
                  steps.find((x) => x.name == thisPage).data.pareti
                )
                  .slice(0, -1)
                  .filter((x) => !JSON.stringify(x).includes('false')).length >
                0
                  ? 'visible'
                  : 'hidden',
            }}
          >
            <CompactPicker
              style={{ marginLeft: 20 }}
              color={steps.find((x) => x.name == thisPage).data.paretiColor}
              onChangeComplete={(v) => {
                var mockArr = steps;
                mockArr.find((x) => x.name == thisPage).data.paretiColor =
                  v.hex;
                setSteps(mockArr);
                forceUpdate();
              }}
              colors={[
                'red',
                'blue',
                'yellow',
                'black',
                'green',
                'orange',
                'pink',
              ]}
            />
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 30,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.pareti.idropittura
              }
              onChange={(v) => {
                var mockArr = steps;

                var c = mockArr.find((x) => x.name == thisPage).data.pareti;
                for (const prop in c) {
                  console.log(`c.${prop} = ${c[prop]}`);
                  if (!prop.includes('Text')) {
                    c[prop] = false;
                  }
                }

                mockArr.find(
                  (x) => x.name == thisPage
                ).data.pareti.idropittura = v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              idropittura
            </Checkbox>
          </Col>
          <Col
            sm={2}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 40,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.pareti.laminato
              }
              onChange={(v) => {
                var mockArr = steps;

                var c = mockArr.find((x) => x.name == thisPage).data.pareti;
                for (const prop in c) {
                  console.log(`c.${prop} = ${c[prop]}`);
                  if (!prop.includes('Text')) {
                    c[prop] = false;
                  }
                }

                mockArr.find((x) => x.name == thisPage).data.pareti.laminato =
                  v.target.checked;
                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              laminato
            </Checkbox>
          </Col>
          <Col
            sm={12}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginRight: 20,
              marginTop: 5,
            }}
          >
            <Checkbox
              animation="jelly"
              color="danger"
              shape="curve"
              icon={<Check className="svg" data-type="svg" />}
              checked={steps.find((x) => x.name == thisPage).data.pareti.altro}
              onChange={(v) => {
                var mockArr = steps;

                var c = mockArr.find((x) => x.name == thisPage).data.pareti;
                for (const prop in c) {
                  console.log(`c.${prop} = ${c[prop]}`);
                  if (!prop.includes('Text')) {
                    c[prop] = false;
                  }
                }

                mockArr.find((x) => x.name == thisPage).data.pareti.altro =
                  v.target.checked;

                if (!v.target.checked) {
                  mockArr.find(
                    (x) => x.name == thisPage
                  ).data.pareti.altroText = '';
                }

                setSteps(mockArr);
                forceUpdate();
              }}
              style={{ marginBottom: 15 }}
            >
              altro
            </Checkbox>
          </Col>
          {steps.find((x) => x.name == thisPage).data.pareti.altro && (
            <Col sm={12}>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Descrizione..."
                value={
                  steps.find((x) => x.name == thisPage).data.pareti.altroText
                }
                onChange={(v) => {
                  var mockArr = steps;
                  mockArr.find(
                    (x) => x.name == thisPage
                  ).data.pareti.altroText = v.target.value;
                  setSteps(mockArr);
                  forceUpdate();
                }}
              />
            </Col>
          )}
        </Row>
      </div>

      <div style={{ marginTop: 10 }}>
        <Row className="mt-4">
          <Col
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: -4,
              fontWeight: 600,
            }}
          >
            Necessità
          </Col>
          <Row>
            <Col
              sm={4}
              style={{
                display: 'flex',
                marginRight: 30,
                marginTop: 10,
                alignItems: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Row>
                <Col sm={5} style={{ alignItems: 'flex-end', display: 'flex' }}>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    step="1"
                    min="0"
                    value={
                      steps.find((x) => x.name == thisPage).data.necessita
                        .ripostiglio
                    }
                    onChange={(v) => {
                      var x = steps;
                      x.find(
                        (y) => y.name == thisPage
                      ).data.necessita.ripostiglio = +v.target.value;
                      setSteps(x);
                      forceUpdate();
                    }}
                  />
                </Col>
                <Col sm={7} style={{ alignItems: 'flex-end', display: 'flex', textAlign:'left' }}>
                  ripostiglio
                </Col>
              </Row>
            </Col>
            <Col
              sm={4}
              style={{
                display: 'flex',
                marginRight: 30,
                marginTop: 10,
                alignItems: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Row>
                <Col sm={5} style={{ alignItems: 'flex-end', display: 'flex' }}>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    step="1"
                    min="0"
                    value={
                      steps.find((x) => x.name == thisPage).data.necessita
                        .reception
                    }
                    onChange={(v) => {
                      var x = steps;
                      x.find(
                        (y) => y.name == thisPage
                      ).data.necessita.reception = +v.target.value;
                      setSteps(x);
                      forceUpdate();
                    }}
                  />
                </Col>
                <Col sm={7} style={{ alignItems: 'flex-end', display: 'flex', textAlign:'left' }}>
                  reception
                </Col>
              </Row>
            </Col>
            <Col
              sm={4}
              style={{
                display: 'flex',
                marginRight: 30,
                marginTop: 10,
                alignItems: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Row>
                <Col sm={5} style={{ alignItems: 'flex-end', display: 'flex' }}>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    step="1"
                    min="0"
                    value={
                      steps.find((x) => x.name == thisPage).data.necessita.bar
                    }
                    onChange={(v) => {
                      var x = steps;
                      x.find((y) => y.name == thisPage).data.necessita.bar = +v
                        .target.value;
                      setSteps(x);
                      forceUpdate();
                    }}
                  />
                </Col>
                <Col sm={7} style={{ alignItems: 'flex-end', display: 'flex', textAlign:'left' }}>
                  bar&emsp;&nbsp;&nbsp;
                </Col>
              </Row>
            </Col>
            <Col
              sm={4}
              style={{
                display: 'flex',
                marginRight: 30,
                marginTop: 10,
                alignItems: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Row>
                <Col sm={5} style={{ alignItems: 'flex-end', display: 'flex' }}>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    step="1"
                    min="0"
                    value={
                      steps.find((x) => x.name == thisPage).data.necessita
                        .salotto
                    }
                    onChange={(v) => {
                      var x = steps;
                      x.find(
                        (y) => y.name == thisPage
                      ).data.necessita.salotto = +v.target.value;
                      setSteps(x);
                      forceUpdate();
                    }}
                  />
                </Col>
                <Col sm={7} style={{ alignItems: 'flex-end', display: 'flex', textAlign:'left' }}>
                  salotto
                </Col>
              </Row>
            </Col>
            <Col
              sm={4}
              style={{
                display: 'flex',
                marginRight: 30,
                marginTop: 10,
                alignItems: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Row>
                <Col sm={5} style={{ alignItems: 'flex-end', display: 'flex' }}>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    step="1"
                    min="0"
                    value={
                      steps.find((x) => x.name == thisPage).data.necessita
                        .postiLavoro
                    }
                    onChange={(v) => {
                      var x = steps;
                      x.find(
                        (y) => y.name == thisPage
                      ).data.necessita.postiLavoro = +v.target.value;
                      setSteps(x);
                      forceUpdate();
                    }}
                  />
                </Col>
                <Col sm={7} style={{ alignItems: 'flex-end', display: 'flex', textAlign:'left' }}>
                  posto/i lavoro
                </Col>
              </Row>
            </Col>
            <Col
              sm={4}
              style={{
                display: 'flex',
                marginRight: 30,
                marginTop: 10,
                alignItems: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Row>
                <Col sm={5} style={{ alignItems: 'flex-end', display: 'flex' }}>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    step="1"
                    min="0"
                    value={
                      steps.find((x) => x.name == thisPage).data.necessita
                        .ufficiChiusi
                    }
                    onChange={(v) => {
                      var x = steps;
                      x.find(
                        (y) => y.name == thisPage
                      ).data.necessita.ufficiChiusi = +v.target.value;
                      setSteps(x);
                      forceUpdate();
                    }}
                  />
                </Col>
                <Col sm={7} style={{ alignItems: 'flex-end', display: 'flex', textAlign:'left' }}>
                  ufficio/i chiuso/i
                </Col>
              </Row>
            </Col>
            <Col
              sm={4}
              style={{
                display: 'flex',
                marginRight: 30,
                marginTop: 10,
                alignItems: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Row>
                <Col sm={5} style={{ alignItems: 'flex-end', display: 'flex' }}>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    step="1"
                    min="0"
                    value={
                      steps.find((x) => x.name == thisPage).data.necessita
                        .ufficiParzialmenteChiusi
                    }
                    onChange={(v) => {
                      var x = steps;
                      x.find(
                        (y) => y.name == thisPage
                      ).data.necessita.ufficiParzialmenteChiusi = +v.target
                        .value;
                      setSteps(x);
                      forceUpdate();
                    }}
                  />
                </Col>
                <Col sm={7} style={{ alignItems: 'flex-end', display: 'flex', textAlign:'left' }}>
                  uff. parz. chiuso
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </div>

      <div style={{ marginTop: 10 }}>
        <Row className="mt-4">
          <Row>
            <Col
              sm={12}
              style={{
                display: 'flex',
                marginRight: 30,
                marginTop: 10,
                alignItems: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Col sm={12} style={{ alignItems: 'flex-end', display: 'flex' }}>
                <Form.Control
                  placeholder="Altro..."
                  size={10}
                  as="textarea"
                  rows={4}
                  value={steps.find((x) => x.name == thisPage).data.altro}
                  onChange={(v) => {
                    var x = steps;
                    x.find((y) => y.name == thisPage).data.altro =
                      v.target.value;
                    setSteps(x);
                    forceUpdate();
                  }}
                />
              </Col>
            </Col>
          </Row>
        </Row>
      </div>

      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'left' }}>
        <Button
          onMouseOver={() => {
            setIsHoveringNextButtonColor(true);
          }}
          onMouseLeave={() => {
            setIsHoveringNextButtonColor(false);
          }}
          variant="outline-light"
          onClick={() => {
            validateThisAndGoToNextPage();
          }}
        >
          <ArrowRight
            className="svg"
            color={!isHoveringNextButtonColor ? 'white' : 'black'}
            data-type="svg"
            size={30}
          />
        </Button>
      </div>

      <div></div>

      <ToastContainer />
    </div>
  );
}

export default Tipologia;
