import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useEffect, useState, useReducer } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import useLocalStorage from 'use-local-storage';

import { useLocalStorageValue } from '@react-hookz/web'; // cjs

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SideMenu from './components/SideMenu';

import { useCookies } from 'react-cookie';

import Middle from './components/Middle';

import {
  SvgLoader,
  SvgProxy,
  AttributeMotion,
  TransformMotion,
} from 'react-svgmt';

function App() {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
   // document.title = 'Exhibition Lab';
  }, []);

  // function goBackOneStep() {
  //   alert('test');

  //   var steps = cookies['exhibitionSteps'];
  //   var currentStepInArray = cookies['exhibitionCurrentStep'];

  //   console.log(steps);
  //   console.log('c', currentStepInArray, 'c');

  //   // setCookie('exhibitionCurrentStep', currentStep - 1, '/');
  // }

  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function (event) {
      // goBackOneStep();
    });
  }, []);

  const [cookies, setCookie] = useCookies(['exhibitionCurrentStep']);

  const pavimentoElements = [];

  var label = '#pavimentoElement';
  var times = 19;
  for (var i = 0; i < times; i++) {
    pavimentoElements.push(label + i.toString());
  }

  const paretiElements = [];

  var label = '#paretiElement';
  var times = 9;
  for (var i = 0; i < times; i++) {
    paretiElements.push(label + i.toString());
  }


  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  useEffect(() => {
    setInterval(() => {
      forceUpdate();
    }, 5000);
  }, []);

  useEffect(() => {
   console.log(".....")
   try {
   // var areStepsInACookie = steps;
    //console.log(areStepsInACookie, 'are?');
    var areStepsInACookie = null

    console.log(areStepsInACookie,"STEPS AT START")
    

    if (areStepsInACookie == null || areStepsInACookie.length < 1) {
      setSteps([
        {
          id: 1,
          name: 'Cliente',
          validated: false,
          currentlyOpen: true,
          data: {
            ragioneSociale: null,
            indirizzoVia: null,
            indirizzoCivico: null,
            indirizzoCAP: null,
            indirizzoProvincia: null,
            telefono: null,
            URL: null,
          },
        },
        {
          id: 2,
          name: 'Referente',
          validated: false,
          currentlyOpen: false,
          data: {
            referente: '',
            telefono: '',
            fax: '',
            cell: '',
            email: '',
          },
        },
        {
          id: 3,
          name: 'Manifestazione',
          validated: false,
          currentlyOpen: false,
          data: {
            manifestazione: null,
            anno: null,
            citta: null,
            periodo: null,
            pad: null,
            post: null,
            mq: null,
            selectedPictures: [],
            likedPictures: [],
            dislikedPictures: [],
          },
        },
        {
          id: 4,
          name: 'Allegati',
          validated: false,
          currentlyOpen: false,
          data: {
            piantaPosteggio: {
              checked: false,
              uploadedFile: false,
              uploadedFileData: {
                originFileName: '',
                fileToken: '',
                isImage: false,
                startingURL: '',
                size: 0,
              },
            },
            regolamentoTecnico: {
              checked: false,
              uploadedFile: false,
              uploadedFileData: {
                originFileName: '',
                fileToken: '',
                isImage: false,
                startingURL: '',
                size: 0,
              },
            },
            logo: {
              checked: false,
              uploadedFile: false,
              uploadedFileData: {
                originFileName: '',
                fileToken: '',
                isImage: false,
                startingURL: '',
                size: 0,
              },
            },
            altro: {
              checked: false,
              uploadedFile: false,
              uploadedFileData: {
                originFileName: '',
                fileToken: '',
                isImage: false,
                startingURL: '',
                size: 0,
              },
            },
            riferimentoProgettoPreliminare: {
              checked: false,
              uploadedFile: false,
              uploadedFileData: {
                originFileName: '',
                fileToken: '',
                isImage: false,
                startingURL: '',
                size: 0,
              },
              addedText: '',
            },
          },
        },
        {
          id: 5,
          name: 'Richieste',
          validated: false,
          currentlyOpen: false,
          data: {
            progetto: false,
            preventivo: false,
            dataIndicativa: false,
            dataTassativa: false,
            data: '',
            fileUploadedToken: '',
            startingURL: '',
            fileUploadedOriginalName: '',
            fileUploadedSize: 0,
            isImage: false,
          },
        },
        {
          id: 6,
          name: 'Invio',
          validated: false,
          currentlyOpen: false,
          data: {
            direttamenteAlCliente: false,
            viaEmail: false,
            aMezzoCorriere: false,
            simulazioneRealtaAumentata: false,
            emailAddress: '',
          },
        },
        {
          id: 7,
          name: 'Tipologia',
          validated: false,
          currentlyOpen: false,
          data: {
            pavimentazioneColor: '#838382',
            paretiColor: '#c6c6c6',
            budget: 0, // in EUR
            hMax: 0, // in meters
            livello: {
              economico: false,
              standard: false,
              medio: false,
              alto: false,
            },
            genere: {
              diProdotto: false,
              istituzionale: false,
              dImmagine: false,
            },
            stand: {
              aperto: false,
              semichiuso: false,
              chiuso: false,
              semichiusoText: '',
            },
            pavimentazione: {
              moquette: false,
              parquet: false,
              pedana: false,
              alluminioMand: false,
              altro: false,
              altroText: '',
            },
            pareti: {
              idropittura: false,
              laminato: false,
              altro: false,
              altroText: '',
            },
            necessita: {
              ripostiglio: 1,
              reception: 1,
              bar: 1,
              salotto: 1,
              postiLavoro: 1,
              ufficiChiusi: 1,
              ufficiParzialmenteChiusi: 1,
            },
            altro: '',
          },
        },
        {
          id: 8,
          name: 'Esposizione',
          validated: false,
          currentlyOpen: false,
          data: {
            prodottiEsposti: '',
            dimensioneProdotti: '',
            modalitaEspositiva: '',
            tipologiaEspositori: '',
            elementoCaratterizzante: '',
            colori: '',
            grafica: '',
          },
        },
        {
          id: 9,
          name: 'Note',
          validated: false,
          currentlyOpen: false,
          data: {
            note: '',
          },
        },
      ]);
      setCookie('exhibitionCurrentStep', 1, '/');
      console.log('not error');
    } else {
      // populateSteps(areStepsInACookie);
    }
  } catch (e) {
    console.log('ERROR', e);
  }

  }, [])


  useEffect(() => {}, []);

  var bg1 = require('./Backgrounds/bg1.jpg');

  return (
    <div>
  {steps != null &&  <div
      style={{
        textAlign: 'center',
        background:
          'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0.9, 0.9, 0.9, 0.6))',
        backgroundPosition: 'center',
        backgroundRepeate: 'no-repeat',
        backgroundsize: 'cover',
        minHeight: '100vh',
        backgroundImage:
          'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0.9, 0.9, 0.9, 0.6)), url(' +
          bg1 +
          ')',
      }}
    >
      <Container>
        <Row>
          <Col xs={3} style={{ color: 'white' }} className="d-none d-lg-block ">
            <SideMenu />
          </Col>
          <Col
            xs={
              /*steps !== undefined &&
              steps !== null &&
              Object.entries(
                steps.find((x) => x.name == 'Tipologia').data
                  .pavimentazione
              )
                .slice(0, -1)
                .filter((x) => !JSON.stringify(x).includes('false'))
                .length > 0 || steps !== undefined &&
                steps !== null || 
                Object.entries(
                  steps.find((x) => x.name == 'Tipologia').data
                    .pareti
                )
                  .slice(0, -1)
                  .filter((x) => !JSON.stringify(x).includes('false'))
                  .length > 0 ? '6' : '9' */
              steps !== undefined &&
              steps !== null &&
              (
                
                Object.entries(steps.find((x) => x.name == 'Tipologia').data.stand).slice(0, -1).filter((x) => !JSON.stringify(x).includes('false')).length > 0 ||
                Object.entries(steps.find((x) => x.name == 'Tipologia').data.genere).filter((x) => !JSON.stringify(x).includes('false')).length > 0 ||
                Object.entries(steps.find((x) => x.name == 'Tipologia').data.livello).filter((x) => !JSON.stringify(x).includes('false')).length > 0 ||
                Object.entries(steps.find((x) => x.name == 'Tipologia').data.pavimentazione).slice(0, -1).filter((x) => !JSON.stringify(x).includes('false')).length > 0 ||
                Object.entries(steps.find((x) => x.name == 'Tipologia').data.pareti).slice(0, -1).filter((x) => !JSON.stringify(x).includes('false')).length > 0
                
              )

                ? '6'
                : '9'
            }
            style={{ color: 'white', display: 'flex', justifyContent: 'left' }}
          >
            <BrowserRouter>
              <Routes>
                <Route exact path="/*" element={<Middle />} />
              </Routes>
            </BrowserRouter>
          </Col>

          {steps !== undefined &&
            steps !== null &&
            (
                
              Object.entries(steps.find((x) => x.name == 'Tipologia').data.stand).slice(0, -1).filter((x) => !JSON.stringify(x).includes('false')).length > 0 ||
              Object.entries(steps.find((x) => x.name == 'Tipologia').data.genere).filter((x) => !JSON.stringify(x).includes('false')).length > 0 ||
              Object.entries(steps.find((x) => x.name == 'Tipologia').data.livello).filter((x) => !JSON.stringify(x).includes('false')).length > 0 ||
              Object.entries(steps.find((x) => x.name == 'Tipologia').data.pavimentazione).slice(0, -1).filter((x) => !JSON.stringify(x).includes('false')).length > 0 ||
              Object.entries(steps.find((x) => x.name == 'Tipologia').data.pareti).slice(0, -1).filter((x) => !JSON.stringify(x).includes('false')).length > 0
              
            ) && (
              <Col
                xs={12}
                sm={3}
                style={{ color: 'white', paddingLeft: 30, paddingTop: 50 }}
              >
                <SvgLoader
                  width={'100%'}
                  height={'20%'}
                  path="https://api.exhibition.tecnodatasystem.eu/main.svg.php"
                >
                  {/*cookies['exhibitionSteps'].find((x) => x.name == 'Allegati')
                    .data.regolamentoTecnico.checked &&
                    pavimentoElements.map((selector, index) => (
                      <AttributeMotion
                        selector={selector}
                        start={{
                          opacity: 0,
                        }}
                        target={{
                          opacity: .2
                        }}
                        formatValue={function() {
                          return .2
                        }}
                      ></AttributeMotion>
                      )) */}

                  {steps !== undefined &&
                    steps !== null &&
                    Object.entries(
                      steps.find((x) => x.name == 'Tipologia').data
                        .pavimentazione
                    )
                      .slice(0, -1)
                      .filter((x) => !JSON.stringify(x).includes('false'))
                      .length > 0 &&
                    pavimentoElements.map((selector, index) => (
                      <SvgProxy
                        selector={selector}
                        fill={
                          steps.find((x) => x.name == 'Tipologia').data
                            .pavimentazioneColor
                        }
                        opacity={1}
                      />
                    ))}

                  {steps !== undefined &&
                    steps !== null &&
                    Object.entries(
                      steps.find((x) => x.name == 'Tipologia').data.pareti
                    )
                      .slice(0, -1)
                      .filter((x) => !JSON.stringify(x).includes('false'))
                      .length > 0 &&
                    paretiElements.map((selector, index) => (
                      <SvgProxy
                        selector={selector}
                        fill={
                          steps.find((x) => x.name == 'Tipologia').data
                            .paretiColor
                        }
                        opacity={1}
                      />
                    ))}
                </SvgLoader>
              </Col>
            )}
        </Row>
      </Container>

      <br />
    </div>} 
    </div>
  );
}

export default App;
