import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Check, Upload, ArrowRight } from 'react-feather';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";



import { useLocalStorageValue } from '@react-hookz/web'; // cjs


function Referente({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [isHoveringNextButtonColor, setIsHoveringNextButtonColor] = useState(
    false
  );

  const navigate = useNavigate();


  const [cookies, setCookie] = useCookies([
    'exhibitionCurrentStep',
  ]);

  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  var thisPage = 'Referente';
  var title = 'Referente';

function updateSingleData(field, value) {
    var x = steps;
    x.find((x) => x.name == thisPage).data[field] = value;
    setSteps(x);
    forceUpdate();
  }

  function showError(message) {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function validateThisAndGoToNextPage() {


    try {
      if (
        steps.find((x) => x.name == thisPage).data.referente
          .length < 6
      ) {
        showError('Compila correttamente il campo Referente');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Referente');
      return;
    }

    try {
      if (
        steps.find((x) => x.name == thisPage).data.telefono
          .length < 6
      ) {
        showError('Compila correttamente il campo Telefono');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Telefono');
      return;
    }

    try {
      if (
        steps.find((x) => x.name == thisPage).data.fax
          .length < 6
      ) {
        showError('Compila correttamente il campo Fax');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Fax');
      return;
    }

    try {
      if (
        steps.find((x) => x.name == thisPage).data.cell
          .length < 9
      ) {
        showError('Compila correttamente il campo Cellulare');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Cellulare');
      return;
    }

    try {
      if (
        steps.find((x) => x.name == thisPage).data.email
          .length < 6 ||
        !steps
          .find((x) => x.name == thisPage)
          .data.email.includes('@')
      ) {
        showError('Compila correttamente il campo Email');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Email');
      return;
    }

    toast.loading('Validazione...', {
      position: 'bottom-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    setTimeout(() => {
      //console.log(steps.);

      console.log(thisPage);
      var index = steps.findIndex(
        (x) => x.name == thisPage
      );

      console.log(index);

      var mockArr = steps;
      mockArr[index].validated = true;
      mockArr[index].currentlyOpen = false;
      mockArr[index + 1].currentlyOpen = true;

      console.log(mockArr);

      setSteps( mockArr );
      setCookie('exhibitionCurrentStep', mockArr[index].id + 1, '/');

      navigate(Math.random().toString(36).substr(2, 34))


      toast.dismiss();
    }, 1000);
  }

  return (
    <div style={{ marginTop: 45, width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <h3>{title}</h3>
      </div>

      <div style={{ marginTop: -15 }}>
      <Row className="mt-4">
          <Col
            sm={3}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Referente
          </Col>
          <Col sm={9} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Referente"
              value={
                steps.find((x) => x.name == thisPage).data
                  .referente
              }
              onChange={(v) => {
                updateSingleData('referente', v.target.value);
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col
            sm={3}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Telefono
          </Col>
          <Col sm={9} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Telefono"
              value={
                steps.find((x) => x.name == thisPage).data
                  .telefono
              }
              onChange={(v) => {
                updateSingleData('telefono', v.target.value);
              }}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col
            sm={3}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Fax
          </Col>
          <Col sm={9} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Fax"
              value={
                steps.find((x) => x.name == thisPage).data
                  .fax
              }
              onChange={(v) => {
                updateSingleData('fax', v.target.value);
              }}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col
            sm={3}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Cellulare
          </Col>
          <Col sm={9} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Cellulare"
              value={
                steps.find((x) => x.name == thisPage).data
                  .cell
              }
              onChange={(v) => {
                updateSingleData('cell', v.target.value);
              }}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col
            sm={3}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Email
          </Col>
          <Col sm={9} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Email"
              value={
                steps.find((x) => x.name == thisPage).data
                  .email
              }
              onChange={(v) => {
                updateSingleData('email', v.target.value);
              }}
            />
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'left' }}>
        <Button
          onMouseOver={() => {
            setIsHoveringNextButtonColor(true);
          }}
          onMouseLeave={() => {
            setIsHoveringNextButtonColor(false);
          }}
          variant="outline-light"
          onClick={() => {
            validateThisAndGoToNextPage();
          }}
        >
          <ArrowRight
            className="svg"
            color={!isHoveringNextButtonColor ? 'white' : 'black'}
            data-type="svg"
            size={30}
          />
        </Button>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Referente;
