import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import { useCookies } from 'react-cookie';

import Richieste from './slides/Richieste';
import Invio from './slides/Invio';
import Referente from './slides/Referente';
import Manifestazione from './slides/Manifestazione';
import Tipologia from './slides/Tipologia';
import Allegati from './slides/Allegati';
import Note from './slides/Note';
import Cliente from './slides/Cliente';
import Esposizione from './slides/Esposizione';

import Send from './slides/Send'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Middle({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [cookies, setCookie] = useCookies([
    'exhibitionSteps',
    'exhibitionCurrentStep',
  ]);

  useEffect(() => {
    var cookieCurrentStep = cookies['exhibitionCurrentStep'];

    if (cookieCurrentStep === undefined) {
      var cookieCurrentStep = 1;
    }

    console.log(cookieCurrentStep, 'cookieCurrentStep');

    setCookie('exhibitionCurrentStep', +cookieCurrentStep, '/');
  }, []);

  return (
    <Col>
      {cookies['exhibitionCurrentStep'] == 1 ? (
        <Cliente />
      ) : cookies['exhibitionCurrentStep'] == 2 ? (
        <Referente />
      ) : cookies['exhibitionCurrentStep'] == 3 ? (
        <Manifestazione />
      ) : cookies['exhibitionCurrentStep'] == 4 ? (
        <Allegati />
      ) : cookies['exhibitionCurrentStep'] == 5 ? (
        <Richieste />
      ) : cookies['exhibitionCurrentStep'] == 6 ? (
        <Invio />
      ) : cookies['exhibitionCurrentStep'] == 7 ? (
        <Tipologia />
      ) : cookies['exhibitionCurrentStep'] == 8 ? (
        <Esposizione />
      ) : cookies['exhibitionCurrentStep'] == 9 ? (
        <Note />
      ) : cookies['exhibitionCurrentStep'] > 9 ? (
        <Send />
      ) : (
        'error_emp'
      )}
    </Col>
  );
}

export default Middle;
