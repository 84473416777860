import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Check, Upload, ArrowRight } from 'react-feather';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import { Checkbox, Radio } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';

import { useLocalStorageValue } from '@react-hookz/web'; // cjs

function Esposizione({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [isHoveringNextButtonColor, setIsHoveringNextButtonColor] = useState(
    false
  );

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['exhibitionCurrentStep']);

  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  var thisPage = 'Esposizione';
  var title = 'Esposizione';

  function updateSingleData(field, value) {
    var x = steps;
    x.find((x) => x.name == thisPage).data[field] = value;
    setSteps(x);
    forceUpdate();
  }

  function showError(message) {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function validateThisAndGoToNextPage() {
    /* try {
      if (+steps.find((x) => x.name == thisPage).data.budget < 0.01) {
        showError('Compila correttamente il campo Budget');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Budget');
      return;
    }
 */

    var proceed = true;

    if (!proceed) return;

    // url libero

    toast.loading('Validazione...', {
      position: 'bottom-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    setTimeout(() => {
      console.log(steps);

      var index = steps.findIndex((x) => x.name == thisPage);

      var mockArr = steps;
      mockArr[index].validated = true;
      mockArr[index].currentlyOpen = false;
      mockArr[index + 1].currentlyOpen = true;
      setSteps(mockArr);
      forceUpdate();
      setCookie('exhibitionCurrentStep', mockArr[index].id + 1, '/');

      toast.dismiss();

      navigate(Math.random().toString(36).substr(2, 34));
    }, 1000);
  }

  return (
    <div style={{ marginTop: 45, width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <h3>{title}</h3>
      </div>

      <div style={{ marginTop: -15 }}>
        <Row className="mt-4">
          <Col
            sm={4}
            className="mt-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            Prodotti esposti
          </Col>
          <Col
            sm={8}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginBottom: 10,
            }}
          >
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Prodotti esposti"
              value={steps.find((x) => x.name == thisPage).data.prodottiEsposti}
              onChange={(v) => {
                updateSingleData('prodottiEsposti', v.target.value);
              }}
            />
          </Col>
          <Col
            sm={4}
            className="mt-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            Dimensione prodotti
          </Col>
          <Col
            sm={8}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginBottom: 10,
            }}
          >
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Dimensione prodotti"
              value={
                steps.find((x) => x.name == thisPage).data.dimensioneProdotti
              }
              onChange={(v) => {
                updateSingleData('dimensioneProdotti', v.target.value);
              }}
            />
          </Col>
          <Col
            sm={4}
            className="mt-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            Modalità espositiva
          </Col>
          <Col
            sm={8}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginBottom: 10,
            }}
          >
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Modalità espositiva"
              value={
                steps.find((x) => x.name == thisPage).data.modalitaEspositiva
              }
              onChange={(v) => {
                updateSingleData('modalitaEspositiva', v.target.value);
              }}
            />
          </Col>
          <Col
            sm={4}
            className="mt-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            Tipologia espositori
          </Col>
          <Col
            sm={8}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginBottom: 10,
            }}
          >
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Tipologia espositori"
              value={
                steps.find((x) => x.name == thisPage).data.tipologiaEspositori
              }
              onChange={(v) => {
                updateSingleData('tipologiaEspositori', v.target.value);
              }}
            />
          </Col>
          <Col
            sm={4}
            className="mt-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            Elemento caratterizzante
          </Col>
          <Col
            sm={8}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginBottom: 10,
            }}
          >
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Elemento caratterizzante"
              value={
                steps.find((x) => x.name == thisPage).data
                  .elementoCaratterizzante
              }
              onChange={(v) => {
                updateSingleData('elementoCaratterizzante', v.target.value);
              }}
            />
          </Col>
          <Col
            sm={4}
            className="mt-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            Colori
          </Col>
          <Col
            sm={8}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginBottom: 10,
            }}
          >
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Colori"
              value={steps.find((x) => x.name == thisPage).data.colori}
              onChange={(v) => {
                updateSingleData('colori', v.target.value);
              }}
            />
          </Col>
          <Col
            sm={4}
            className="mt-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            Grafica
          </Col>
          <Col
            sm={8}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginBottom: 10,
            }}
          >
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Grafica"
              value={steps.find((x) => x.name == thisPage).data.grafica}
              onChange={(v) => {
                updateSingleData('grafica', v.target.value);
              }}
            />
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'left' }}>
        <Button
          onMouseOver={() => {
            setIsHoveringNextButtonColor(true);
          }}
          onMouseLeave={() => {
            setIsHoveringNextButtonColor(false);
          }}
          variant="outline-light"
          onClick={() => {
            validateThisAndGoToNextPage();
          }}
        >
          <ArrowRight
            className="svg"
            color={!isHoveringNextButtonColor ? 'white' : 'black'}
            data-type="svg"
            size={30}
          />
        </Button>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Esposizione;
