import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Check, Upload, ArrowRight, Trash2 } from 'react-feather';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Checkbox, Radio } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';
import { useNavigate } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Dropzone from 'react-dropzone';

import axios from 'axios';

import { useLocalStorageValue } from '@react-hookz/web'; // cjs

function Allegati({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [isHoveringNextButtonColor, setIsHoveringNextButtonColor] = useState(
    false
  );

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['exhibitionCurrentStep']);

  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  var thisPage = 'Allegati';
  var title = 'Allegati';

  const [
    piantaPosteggioUploadButtonLabel,
    setPiantaPosteggioUploadButtonLabel,
  ] = useState('Carica');

  function updateSingleData(field, value) {
    var x = steps;
    x.find((x) => x.name == thisPage).data[field] = value;
    setSteps(x);
    forceUpdate();
  }

  function showError(message) {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function removeUploadedFile(field, showErrorMessage = false) {
    var x = steps;
    x.find((x) => x.name == thisPage).data[field].uploadedFileData.fileToken =
      '';
    x.find((x) => x.name == thisPage).data[field].uploadedFileData.startingURL =
      '';
    x.find((x) => x.name == thisPage).data[
      field
    ].uploadedFileData.originFileName = '';
    x.find((x) => x.name == thisPage).data[field].uploadedFileData.size = 0;
    x.find((x) => x.name == thisPage).data[
      field
    ].uploadedFileData.isImage = false;
    x.find((x) => x.name == thisPage).data[field].uploadedFile = false;
    setSteps(x);
    forceUpdate();
    if (showErrorMessage) {
      showError('Il file che hai tentato di caricare è troppo grande.');
    }
  }

  function uploadAttachmentToTheServer(field, file) {
    console.log(file);
    var toSend = new FormData();
    toSend.append('fileBase64', file[0]);
    console.log(toSend);

    toast.loading('Caricamento in corso...', {
      position: 'bottom-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    axios({
      method: 'post',
      url:
        'https://api.exhibition.tecnodatasystem.eu/uploadProjectFromTheUser.php',
      data: toSend,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.status == 'ok') {
          var x = steps;
          x.find((x) => x.name == thisPage).data[
            field
          ].uploadedFileData.fileToken = response.data.message;
          x.find((x) => x.name == thisPage).data[
            field
          ].uploadedFileData.startingURL = response.data.startingURL;
          x.find((x) => x.name == thisPage).data[
            field
          ].uploadedFileData.originFileName = response.data.originFileName;
          x.find((x) => x.name == thisPage).data[field].uploadedFileData.size =
            response.data.size;
          x.find((x) => x.name == thisPage).data[
            field
          ].uploadedFileData.isImage = response.data.isImage;
          x.find((x) => x.name == thisPage).data[field].uploadedFile = true;
          setSteps(x);
          forceUpdate();

          setTimeout(() => {
            toast.dismiss();
            setTimeout(() => {
              toast.success('Caricamento completato!', {
                position: 'bottom-right',
                autoClose: 3500,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: 'colored',
              });
            }, 150);
          }, 500);
        } else {
          if (response.data.message == 'file_size_too_large') {
            removeUploadedFile(field, true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        alert('Errore nel caricamento del file.');
      });
  }

  function validateThisAndGoToNextPage() {
    /*  try {
      if (
        steps.find((x) => x.name == thisPage).data
          .ragioneSociale.length < 3
      ) {
        showError('Compila correttamente il campo Ragione Sociale');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Ragione Sociale');
      return;
    } */

    // the above only as a sample in case it needs any form of validation

    toast.loading('Validazione...', {
      position: 'bottom-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    setTimeout(() => {
      console.log(steps);

      var index = steps.findIndex((x) => x.name == thisPage);

      var mockArr = steps;
      mockArr[index].validated = true;
      mockArr[index].currentlyOpen = false;
      mockArr[index + 1].currentlyOpen = true;
      setSteps(mockArr);
      setCookie('exhibitionCurrentStep', mockArr[index].id + 1, '/');

      navigate(Math.random().toString(36).substr(2, 34));

      toast.dismiss();
    }, 1000);
  }

  return (
    <div style={{ marginTop: 45, width: '100%' }}>
      <div
        style={{ display: 'flex', justifyContent: 'left', marginBottom: 20 }}
      >
        <h3>{title}</h3>
      </div>

      <div style={{ marginTop: -15, display: 'flex', justifyContent: 'left' }}>
        <Row sm={12} className="mt-4">
          <Col
            sm={12}
            style={{ justifyContent: 'flex-start', display: 'flex' }}
          >
            <Checkbox
              shape="curve"
              animation="jelly"
              color="danger"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.piantaPosteggio
                  .checked
              }
              onChange={(v) => {
                var x = steps;

                if (!v.target.checked) {
                  if (
                    x.find((x) => x.name == thisPage).data.piantaPosteggio
                      .uploadedFile
                  ) {
                    var y = window.confirm(
                      'Attenzione! Hai un file caricato. Continuando eliminerai il caricamento.'
                    );
                    if (!y) {
                      return;
                    }
                  }
                }

                x.find((x) => x.name == thisPage).data.piantaPosteggio.checked =
                  v.target.checked;

                if (!v.target.checked) {
                  removeUploadedFile('piantaPosteggio');
                }

                setSteps(x);
                forceUpdate();
              }}
              style={{ marginBottom: 23 }}
            >
              Pianta posteggio
            </Checkbox>
          </Col>

          <Col
            sm={8}
            style={{ justifyContent: 'right', display: 'flex', marginTop: 15 }}
          >
            {steps.find((x) => x.name == thisPage).data.piantaPosteggio
              .checked && (
              <Dropzone
                onDrop={(acceptedFiles) =>
                  uploadAttachmentToTheServer('piantaPosteggio', acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{ marginBottom: 2, marginTop: -15 }}
                  >
                    <input {...getInputProps()} />
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          Limite massimo 20 MiB.
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        style={{
                          position: 'relative',
                          right: 0,
                          width: '150%',
                        }}
                      >
                        <Upload className="svg" data-type="svg" />
                        {steps.find((x) => x.name == thisPage).data
                          .piantaPosteggio.uploadedFile
                          ? steps
                              .find((x) => x.name == thisPage)
                              .data.piantaPosteggio.uploadedFileData.originFileName.substr(
                                0,
                                5
                              ) + '...'
                          : 'Carica'}
                      </Button>
                    </OverlayTrigger>
                  </div>
                )}
              </Dropzone>
            )}
          </Col>
          <Col
            sm={12}
            style={{ marginTop: 10, display: 'flex', justifyContent: 'left' }}
          >
            {steps.find((x) => x.name == thisPage).data.piantaPosteggio
              .uploadedFile &&
              steps.find((x) => x.name == thisPage).data.piantaPosteggio
                .checked && (
                <span
                  onClick={() => {
                    removeUploadedFile('piantaPosteggio');
                  }}
                  style={{ marginTop: 8, cursor: 'pointer', fontSize: 15 }}
                >
                  <Trash2 className="svg" data-type="svg" size={15} />
                  &nbsp;Rimuovi caricamento
                </span>
              )}
          </Col>
        </Row>
      </div>

      <hr />

      <div style={{ marginTop: -15, display: 'flex', justifyContent: 'left' }}>
        <Row sm={12} className="mt-4">
          <Col
            sm={12}
            style={{ justifyContent: 'flex-start', display: 'flex' }}
          >
            <Checkbox
              shape="curve"
              animation="jelly"
              color="danger"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data.regolamentoTecnico
                  .checked
              }
              onChange={(v) => {
                var x = steps;

                if (!v.target.checked) {
                  if (
                    x.find((x) => x.name == thisPage).data.regolamentoTecnico
                      .uploadedFile
                  ) {
                    var y = window.confirm(
                      'Attenzione! Hai un file caricato. Continuando eliminerai il caricamento.'
                    );
                    if (!y) {
                      return;
                    }
                  }
                }

                x.find(
                  (x) => x.name == thisPage
                ).data.regolamentoTecnico.checked = v.target.checked;

                if (!v.target.checked) {
                  removeUploadedFile('regolamentoTecnico');
                }

                setSteps(x);
                forceUpdate();
              }}
              style={{ marginBottom: 23 }}
            >
              Regolamento tecnico
            </Checkbox>
          </Col>

          <Col
            sm={8}
            style={{ justifyContent: 'right', display: 'flex', marginTop: 15 }}
          >
            {steps.find((x) => x.name == thisPage).data.regolamentoTecnico
              .checked && (
              <Dropzone
                onDrop={(acceptedFiles) =>
                  uploadAttachmentToTheServer(
                    'regolamentoTecnico',
                    acceptedFiles
                  )
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{ marginBottom: 2, marginTop: -15 }}
                  >
                    <input {...getInputProps()} />
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          Limite massimo 20 MiB.
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        style={{
                          position: 'relative',
                          right: 0,
                          width: '150%',
                        }}
                      >
                        <Upload className="svg" data-type="svg" />
                        {steps.find((x) => x.name == thisPage).data
                          .regolamentoTecnico.uploadedFile
                          ? steps
                              .find((x) => x.name == thisPage)
                              .data.regolamentoTecnico.uploadedFileData.originFileName.substr(
                                0,
                                5
                              ) + '...'
                          : 'Carica'}
                      </Button>
                    </OverlayTrigger>
                  </div>
                )}
              </Dropzone>
            )}
          </Col>
          <Col
            sm={12}
            style={{ marginTop: 10, display: 'flex', justifyContent: 'left' }}
          >
            {steps.find((x) => x.name == thisPage).data.regolamentoTecnico
              .uploadedFile &&
              steps.find((x) => x.name == thisPage).data.regolamentoTecnico
                .checked && (
                <span
                  onClick={() => {
                    removeUploadedFile('regolamentoTecnico');
                  }}
                  style={{ marginTop: 8, cursor: 'pointer', fontSize: 15 }}
                >
                  <Trash2 className="svg" data-type="svg" size={15} />
                  &nbsp;Rimuovi caricamento
                </span>
              )}
          </Col>
        </Row>
      </div>

      <hr />

      <div style={{ marginTop: -15, display: 'flex', justifyContent: 'left' }}>
        <Row sm={12} className="mt-4">
          <Col
            sm={12}
            style={{ justifyContent: 'flex-start', display: 'flex' }}
          >
            <Checkbox
              shape="curve"
              animation="jelly"
              color="danger"
              icon={<Check className="svg" data-type="svg" />}
              checked={steps.find((x) => x.name == thisPage).data.logo.checked}
              onChange={(v) => {
                var x = steps;

                if (!v.target.checked) {
                  if (
                    x.find((x) => x.name == thisPage).data.logo.uploadedFile
                  ) {
                    var y = window.confirm(
                      'Attenzione! Hai un file caricato. Continuando eliminerai il caricamento.'
                    );
                    if (!y) {
                      return;
                    }
                  }
                }

                x.find((x) => x.name == thisPage).data.logo.checked =
                  v.target.checked;

                if (!v.target.checked) {
                  removeUploadedFile('logo');
                }

                setSteps(x);
                forceUpdate();
              }}
              style={{ marginBottom: 23 }}
            >
              Logo
            </Checkbox>
          </Col>

          <Col
            sm={8}
            style={{ justifyContent: 'right', display: 'flex', marginTop: 15 }}
          >
            {steps.find((x) => x.name == thisPage).data.logo.checked && (
              <Dropzone
                onDrop={(acceptedFiles) =>
                  uploadAttachmentToTheServer('logo', acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{ marginBottom: 2, marginTop: -15 }}
                  >
                    <input {...getInputProps()} />
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          Limite massimo 20 MiB.
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        style={{
                          position: 'relative',
                          right: 0,
                          width: '150%',
                        }}
                      >
                        <Upload className="svg" data-type="svg" />
                        {steps.find((x) => x.name == thisPage).data.logo
                          .uploadedFile
                          ? steps
                              .find((x) => x.name == thisPage)
                              .data.logo.uploadedFileData.originFileName.substr(
                                0,
                                5
                              ) + '...'
                          : 'Carica'}
                      </Button>
                    </OverlayTrigger>
                  </div>
                )}
              </Dropzone>
            )}
          </Col>
          <Col
            sm={12}
            style={{ marginTop: 10, display: 'flex', justifyContent: 'left' }}
          >
            {steps.find((x) => x.name == thisPage).data.logo.uploadedFile &&
              steps.find((x) => x.name == thisPage).data.logo.checked && (
                <span
                  onClick={() => {
                    removeUploadedFile('logo');
                  }}
                  style={{ marginTop: 8, cursor: 'pointer', fontSize: 15 }}
                >
                  <Trash2 className="svg" data-type="svg" size={15} />
                  &nbsp;Rimuovi caricamento
                </span>
              )}
          </Col>
        </Row>
      </div>

      <hr />

      <div style={{ marginTop: -15, display: 'flex', justifyContent: 'left' }}>
        <Row sm={12} className="mt-4">
          <Col
            sm={12}
            style={{ justifyContent: 'flex-start', display: 'flex' }}
          >
            <Checkbox
              shape="curve"
              animation="jelly"
              color="danger"
              icon={<Check className="svg" data-type="svg" />}
              checked={steps.find((x) => x.name == thisPage).data.altro.checked}
              onChange={(v) => {
                var x = steps;

                if (!v.target.checked) {
                  if (
                    x.find((x) => x.name == thisPage).data.altro.uploadedFile
                  ) {
                    var y = window.confirm(
                      'Attenzione! Hai un file caricato. Continuando eliminerai il caricamento.'
                    );
                    if (!y) {
                      return;
                    }
                  }
                }

                x.find((x) => x.name == thisPage).data.altro.checked =
                  v.target.checked;

                if (!v.target.checked) {
                  removeUploadedFile('altro');
                }

                setSteps(x);
                forceUpdate();
              }}
              style={{ marginBottom: 23 }}
            >
              Altro
            </Checkbox>
          </Col>

          <Col
            sm={8}
            style={{ justifyContent: 'right', display: 'flex', marginTop: 15 }}
          >
            {steps.find((x) => x.name == thisPage).data.altro.checked && (
              <Dropzone
                onDrop={(acceptedFiles) =>
                  uploadAttachmentToTheServer('altro', acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{ marginBottom: 2, marginTop: -15 }}
                  >
                    <input {...getInputProps()} />
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          Limite massimo 20 MiB.
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        style={{
                          position: 'relative',
                          right: 0,
                          width: '150%',
                        }}
                      >
                        <Upload className="svg" data-type="svg" />
                        {steps.find((x) => x.name == thisPage).data.altro
                          .uploadedFile
                          ? steps
                              .find((x) => x.name == thisPage)
                              .data.altro.uploadedFileData.originFileName.substr(
                                0,
                                5
                              ) + '...'
                          : 'Carica'}
                      </Button>
                    </OverlayTrigger>
                  </div>
                )}
              </Dropzone>
            )}
          </Col>
          <Col
            sm={12}
            style={{ marginTop: 10, display: 'flex', justifyContent: 'left' }}
          >
            {steps.find((x) => x.name == thisPage).data.altro.uploadedFile &&
              steps.find((x) => x.name == thisPage).data.altro.checked && (
                <span
                  onClick={() => {
                    removeUploadedFile('altro');
                  }}
                  style={{ marginTop: 8, cursor: 'pointer', fontSize: 15 }}
                >
                  <Trash2 className="svg" data-type="svg" size={15} />
                  &nbsp;Rimuovi caricamento
                </span>
              )}
          </Col>
        </Row>
      </div>

      <hr />

      <div style={{ marginTop: -15, display: 'flex', justifyContent: 'left' }}>
        <Row sm={12} className="mt-4">
          <Col
            sm={12}
            style={{ justifyContent: 'flex-start', display: 'flex' }}
          >
            <Checkbox
              shape="curve"
              animation="jelly"
              color="danger"
              icon={<Check className="svg" data-type="svg" />}
              checked={
                steps.find((x) => x.name == thisPage).data
                  .riferimentoProgettoPreliminare.checked
              }
              onChange={(v) => {
                var x = steps;

                if (!v.target.checked) {
                  if (
                    x.find((x) => x.name == thisPage).data
                      .riferimentoProgettoPreliminare.uploadedFile
                  ) {
                    var y = window.confirm(
                      'Attenzione! Hai un file caricato. Continuando eliminerai il caricamento.'
                    );
                    if (!y) {
                      return;
                    }
                  }
                }

                x.find(
                  (x) => x.name == thisPage
                ).data.riferimentoProgettoPreliminare.checked =
                  v.target.checked;

                if (!v.target.checked) {
                  removeUploadedFile('riferimentoProgettoPreliminare');
                }

                setSteps(x);
                forceUpdate();
              }}
              style={{ marginBottom: 23 }}
            >
              Riferimento al progetto preliminare
            </Checkbox>
          </Col>

          {steps.find((x) => x.name == thisPage).data
            .riferimentoProgettoPreliminare.checked && (
            <Col
              sm={12}
              style={{
                justifyContent: 'flex-start',
                display: 'flex',
                marginBottom: 15,
              }}
            >
              <Form.Control
                type="text"
                placeholder="Scrivere qua..."
                value={steps.find((x) => x.name == thisPage).data.addedText}
                onChange={(v) => {
                  var x = steps;
                  x.find((y) => y.name == thisPage).data.addedText =
                    v.target.value;
                  setSteps(x);
                  forceUpdate();
                }}
              />
            </Col>
          )}

          <Col
            sm={8}
            style={{ justifyContent: 'right', display: 'flex', marginTop: 15 }}
          >
            {steps.find((x) => x.name == thisPage).data
              .riferimentoProgettoPreliminare.checked && (
              <Dropzone
                onDrop={(acceptedFiles) =>
                  uploadAttachmentToTheServer(
                    'riferimentoProgettoPreliminare',
                    acceptedFiles
                  )
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{ marginBottom: 2, marginTop: -15 }}
                  >
                    <input {...getInputProps()} />
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          Limite massimo 20 MiB.
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        style={{
                          position: 'relative',
                          right: 0,
                          width: '150%',
                        }}
                      >
                        <Upload className="svg" data-type="svg" />
                        {steps.find((x) => x.name == thisPage).data
                          .riferimentoProgettoPreliminare.uploadedFile
                          ? steps
                              .find((x) => x.name == thisPage)
                              .data.riferimentoProgettoPreliminare.uploadedFileData.originFileName.substr(
                                0,
                                5
                              ) + '...'
                          : 'Carica'}
                      </Button>
                    </OverlayTrigger>
                  </div>
                )}
              </Dropzone>
            )}
          </Col>
          <Col
            sm={12}
            style={{ marginTop: 10, display: 'flex', justifyContent: 'left' }}
          >
            {steps.find((x) => x.name == thisPage).data
              .riferimentoProgettoPreliminare.uploadedFile &&
              steps.find((x) => x.name == thisPage).data
                .riferimentoProgettoPreliminare.checked && (
                <span
                  onClick={() => {
                    removeUploadedFile('riferimentoProgettoPreliminare');
                  }}
                  style={{ marginTop: 8, cursor: 'pointer', fontSize: 15 }}
                >
                  <Trash2 className="svg" data-type="svg" size={15} />
                  &nbsp;Rimuovi caricamento
                </span>
              )}
          </Col>
        </Row>
      </div>

      <hr />

      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'left' }}>
        <Button
          onMouseOver={() => {
            setIsHoveringNextButtonColor(true);
          }}
          onMouseLeave={() => {
            setIsHoveringNextButtonColor(false);
          }}
          variant="outline-light"
          onClick={() => {
            validateThisAndGoToNextPage();
          }}
        >
          <ArrowRight
            className="svg"
            color={!isHoveringNextButtonColor ? 'white' : 'black'}
            data-type="svg"
            size={30}
          />
        </Button>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Allegati;
