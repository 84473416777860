import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//import { IoCheckmarkOutline } from 'react-icons/io5';

import { Check } from 'react-feather';

import { useCookies } from 'react-cookie';

import { useLocalStorageValue } from '@react-hookz/web'; // cjs


function SideMenu({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  //let place = history.location.state.place

  //Cookies.set('exhibitionSteps', 'value', { expires: 7 })

  const [cookies, setCookie] = useCookies([
    'exhibitionCurrentStep',
  ]);

  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  function populateSteps(stepsFromTheCookie) {}


  return (
    <div style={{ paddingTop: 50 }}>
      <Container>
        <div>
          {steps !== undefined &&
            steps.map((element, index) => (
              <Row style={{ paddingBottom: 0 }} key={element.id}>
                <Col xs={2} style={{ color: 'white' }}>
                  {element.validated ? (
                    <div>
                      <div
                        style={{
                          backgroundColor: '#e53935',
                          borderRadius: 50,
                          width: 28.5,
                          height: 28.5,
                          border: '1.5px solid #fff',
                        }}
                      >
                        <Check
                          className="svg"
                          data-type="svg"
                          size={22}
                          style={{ paddingTop: 2, paddingLeft: 2 }}
                          color="white"
                        />
                      </div>
                      <div
                        style={{
                          borderLeft: '2px solid grey',
                          height:
                            index != steps.length - 1
                              ? 40
                              : 0,
                          position: 'relative',
                          left: '56%',
                          marginTop: 0,
                        }}
                      ></div>
                    </div>
                  ) : (
                    <div>
                      {element.currentlyOpen ? (
                        <div
                          style={{
                            backgroundColor: '#e53935',
                            borderRadius: 50,
                            width: 28.5,
                            height: 28.5,
                            border: '1.5px solid #fff',
                          }}
                        >
                          <Check
                            className="svg"
                            data-type="svg"
                            color="#e53935"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: 'white',
                            borderRadius: 50,
                            width: 29,
                            height: 29,
                            border: '2px solid #e53935',
                          }}
                        >
                          <Check
                            className="svg"
                            data-type="svg"
                            color="white"
                          />
                        </div>
                      )}
                      <div
                        style={{
                          borderLeft: '2px solid grey',
                          height:
                            index != steps.length - 1
                              ? 40
                              : 0, // 100 if you want the added "Invia la scheda" circle
                          position: 'relative',
                          left: '56%',
                          marginTop: 0,
                        }}
                      ></div>
                    </div>
                  )}
                </Col>
                <Col
                  xs={10}
                  style={{
                    color: 'white',
                    justifyContent: 'left',
                    display: 'flex',
                  }}
                >
                  <div>{element.name.toUpperCase()}</div>
                </Col>
              </Row>
            ))}

       {/*  <Row style={{ paddingBottom: 0, marginLeft:-24 }} key={999}>
        
              <Col
                xs={4}
                style={{
                  color: 'white',
                  justifyContent: 'left',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: 50,
                    width: 50,
                    height: 50,
                    border: '2px solid #e53935',
                  }}
                >
                  <Check className="svg" data-type="svg" color="white" />
                </div>
              </Col>
              <Col
                xs={8}
                style={{
                  color: 'white',
                  justifyContent: 'left',
                  display: 'flex',
                  marginLeft:-30,
                  marginTop:10,
                  textTransform:'uppercase'
                }}
              >
                Invia la Scheda
              </Col>
          
          </Row> */}  
        </div>
      </Container>
    </div>
  );
}

export default SideMenu;
