import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import Dropzone from 'react-dropzone';

import { Checkbox, Radio } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';

import { IoCheckmarkOutline } from 'react-icons/io5';

import { useCookies } from 'react-cookie';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  Check,
  Upload,
  ArrowRight,
  Circle,
  CheckCircle,
  Maximize2,
} from 'react-feather';

import Form from 'react-bootstrap/Form';

import { useDropzone } from 'react-dropzone';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import axios from 'axios';
import Image from 'react-bootstrap/Image';

import Modal from 'react-bootstrap/Modal';


import { useLocalStorageValue } from '@react-hookz/web'; // cjs

function Invio({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [isHoveringNextButtonColor, setIsHoveringNextButtonColor] = useState(
    false
  );

  const navigate = useNavigate();

  const [
    projectUploadedFromTheUserToken,
    setProjectUploadedFromTheUserToken,
  ] = useState(null);

  const [fileUploadedOriginalName, setFileUploadedOriginalName] = useState('');
  const [fileUploadedSize, setFileUploadedSize] = useState(0);

  const [startingURL, setStartingURL] = useState('');

  const [cookies, setCookie] = useCookies([
    'exhibitionCurrentStep',
  ]);

  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  const [showFullPicture, setShowFullPicture] = useState(false);

  var title = 'Opzioni di invio';
  var thisPage = 'Invio';

  // page starts

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Limite massimo 20 MiB.
    </Tooltip>
  );

  function showError(message) {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function validateThisAndGoToNextPage() {
    try {
      if (
        !steps.find((x) => x.name == thisPage).data.direttamenteAlCliente &&
        !steps.find((x) => x.name == thisPage).data.viaEmail  &&
        !steps.find((x) => x.name == thisPage).data.aMezzoCorriere  
      ) {
        showError("Seleziona un'opzione tra: direttamente al cliente, via email, a mezzo corriere");
        return;
      }
    } catch (e) {
      showError("Seleziona un'opzione tra: direttamente al cliente, via email, a mezzo corriere");
      return;
    }

    try {
      if (
        steps.find((x) => x.name == thisPage).data.viaEmail
        &&
        steps.find((x) => x.name == thisPage).data.emailAddress.length < 5
      ) {
        showError('Inserisci un indirizzo email valido');
        return;
      }
    } catch (e) {
      showError('Inserisci un indirizzo email valido');
      return;
    }


    try {
      if (
        steps.find((x) => x.name == thisPage).data.viaEmail
        &&
        steps.find((x) => x.name == thisPage).data.emailAddress.length > 4
      ) {


        if(!steps.find((x) => x.name == thisPage).data.emailAddress.includes("@")) {
          showError('Inserisci un indirizzo email valido');
          return;
        }


        
      }
    } catch (e) {
    
    }




    toast.loading('Validazione...', {
      position: 'bottom-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    setTimeout(() => {
      console.log(steps);

      var index = steps.findIndex(
        (x) => x.name == thisPage
      );

      var mockArr = steps;
      mockArr[index].validated = true;
      mockArr[index].currentlyOpen = false;
      mockArr[index + 1].currentlyOpen = true;
      setSteps(mockArr)
      setCookie('exhibitionCurrentStep', mockArr[index].id + 1, '/');

      toast.dismiss();

      navigate(Math.random().toString(36).substr(2, 34));

    }, 1000);
  }

  useEffect(() => {
    updateSingleData('simulazioneRealtaAumentata',true)
  }, [])

  function updateSingleData(field, value) {
    var x = steps;
    x.find((x) => x.name == thisPage).data[field] = value;
    setSteps(x);
    forceUpdate();
  }

  return (
    <div style={{ marginTop: 45 }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <h3>{title}</h3>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          marginTop: 10,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >

<div style={{ marginTop: 15 }}>
          <Checkbox
            animation="jelly"
            color="danger"
            shape="curve"
            icon={<Check className="svg" data-type="svg" />}
            checked={
              steps.find((x) => x.name == thisPage).data
                .simulazioneRealtaAumentata
            }
            onChange={(v) => {
              //updateSingleData('simulazioneRealtaAumentata', v.target.checked);
            }}
            style={{ marginBottom: 20 }}
          >
            simulazione in realtà aumentata 360°
          </Checkbox>
        </div>

        <div style={{ marginTop: 10 }}>
          <Checkbox
            animation="jelly"
            color="danger"
            shape="curve"
            icon={<Check className="svg" data-type="svg" />}
            checked={
              steps.find((x) => x.name == thisPage).data
                .direttamenteAlCliente
            }
            onChange={(v) => {
              updateSingleData('direttamenteAlCliente', v.target.checked);
            }}
            style={{ marginBottom: 15 }}
          >
            direttamente al cliente
          </Checkbox>
        </div>
        <div style={{ marginTop: 15 }}>
          <Checkbox
            animation="jelly"
            color="danger"
            shape="curve"
            icon={<Check className="svg" data-type="svg" />}
            checked={
              steps.find((x) => x.name == thisPage).data
                .viaEmail
            }
            onChange={(v) => {
              updateSingleData('viaEmail', v.target.checked);
            }}
            style={{ marginBottom: 15 }}
          >
            via email
          </Checkbox>
        </div>

        {steps.find((x) => x.name == thisPage).data
          .viaEmail && (
          <div style={{ marginBottom: 15, width: '50%' }}>
            <Form.Control
              type="text"
              placeholder="Indirizzo email"
              //aria-label="Disabled input example"
              value={
                steps.find((x) => x.name == thisPage).data
                  .emailAddress
              }
              onChange={(v) => {
                updateSingleData('emailAddress', v.target.value);
              }}
            />
          </div>
        )}

        <div style={{ marginTop: 15 }}>
          <Checkbox
            animation="jelly"
            color="danger"
            shape="curve"
            icon={<Check className="svg" data-type="svg" />}
            checked={
              steps.find((x) => x.name == thisPage).data
                .aMezzoCorriere
            }
            onChange={(v) => {
              updateSingleData('aMezzoCorriere', v.target.checked);
            }}
            style={{ marginBottom: 15 }}
          >
            a mezzo corriere
          </Checkbox>
        </div>

  

        <div style={{ marginTop: 30 }}>
          <Button
            onMouseOver={() => {
              setIsHoveringNextButtonColor(true);
            }}
            onMouseLeave={() => {
              setIsHoveringNextButtonColor(false);
            }}
            variant="outline-light"
            onClick={() => {
              validateThisAndGoToNextPage();
            }}
          >
            <ArrowRight
              className="svg"
              color={!isHoveringNextButtonColor ? 'white' : 'black'}
              data-type="svg"
              size={30}
            />
          </Button>
        </div>
      </div>

      <ToastContainer />

      <Modal
        show={showFullPicture}
        fullscreen={false}
        size="lg"
        centered
        onHide={() => {
          setShowFullPicture(false);
        }}
      >
        <Image
          fluid
          src={
            steps.find((x) => x.name == thisPage).data
              .startingURL +
            steps.find((x) => x.name == thisPage).data
              .fileUploadedToken
          }
        ></Image>
      </Modal>
    </div>
  );
}

export default Invio;
