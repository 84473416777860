import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import Dropzone from 'react-dropzone';

import { Checkbox, Radio } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';

import { IoCheckmarkOutline } from 'react-icons/io5';

import { useCookies } from 'react-cookie';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  Check,
  Upload,
  ArrowRight,
  Circle,
  CheckCircle,
  Maximize2,
} from 'react-feather';

import Form from 'react-bootstrap/Form';

import { useDropzone } from 'react-dropzone';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import axios from 'axios';
import Image from 'react-bootstrap/Image';

import Modal from 'react-bootstrap/Modal';

import { useLocalStorageValue } from '@react-hookz/web'; // cjs

function Richieste({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [isHoveringNextButtonColor, setIsHoveringNextButtonColor] = useState(
    false
  );

  const navigate = useNavigate();

  const [
    projectUploadedFromTheUserToken,
    setProjectUploadedFromTheUserToken,
  ] = useState(null);

  const [fileUploadedOriginalName, setFileUploadedOriginalName] = useState('');
  const [fileUploadedSize, setFileUploadedSize] = useState(0);

  const [startingURL, setStartingURL] = useState('');

  const [cookies, setCookie] = useCookies([
    'exhibitionCurrentStep',
  ]);

  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  const [showFullPicture, setShowFullPicture] = useState(false);

  var title = 'Richieste';
  var thisPage = 'Richieste';

  // page starts

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Limite massimo 20 MiB.
    </Tooltip>
  );

  function showError(message) {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function validateThisAndGoToNextPage() {
    try {
      if (
        steps.find((x) => x.name == thisPage).data.data <
        10
      ) {
        showError('Compila correttamente il campo Data');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Data');
      return;
    }

    try {
      if (
        steps.find((x) => x.name == thisPage).data
          .progetto == null &&
        steps.find((x) => x.name == thisPage).data
          .preventivo == null
      ) {
        showError("Scegli un'opzione tra Progetto e Preventivo");
        return;
      }
    } catch (e) {
      showError("Scegli un'opzione tra Progetto e Preventivo");
      return;
    }

    try {
      if (
        steps.find((x) => x.name == thisPage).data
          .progetto == false &&
        steps.find((x) => x.name == thisPage).data
          .preventivo == false
      ) {
        showError("Scegli un'opzione tra Progetto e Preventivo");
        return;
      }
    } catch (e) {
      showError("Scegli un'opzione tra Progetto e Preventivo");
      return;
    }

    try {
      if (
        steps.find((x) => x.name == thisPage).data
          .dataTassativa == false &&
        steps.find((x) => x.name == thisPage).data
          .dataIndicativa == false
      ) {
        showError("Scegli un'opzione tra Data indicativa e Data tassativa");
        return;
      }
    } catch (e) {
      showError("Scegli un'opzione tra Data indicativa e Data tassativa");
      return;
    }

    toast.loading('Validazione...', {
      position: 'bottom-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    setTimeout(() => {
      console.log(steps);

      var index = steps.findIndex(
        (x) => x.name == thisPage
      );

      var mockArr = steps;
      mockArr[index].validated = true;
      mockArr[index].currentlyOpen = false;
      mockArr[index + 1].currentlyOpen = true;
      setSteps(mockArr)
      setCookie('exhibitionCurrentStep', mockArr[index].id + 1, '/');

      toast.dismiss();

      navigate(Math.random().toString(36).substr(2, 34));
    }, 1000);
  }

  function updateSingleData(field, value) {
    var x = steps;
    x.find((x) => x.name == thisPage).data[field] = value;
    setSteps(x);
    forceUpdate();
  }


  function uploadProjectToTheServer(file) {
    console.log(file);
    var toSend = new FormData();
    toSend.append('fileBase64', file[0]);
    console.log(toSend);

    toast.loading('Caricamento in corso...', {
      position: 'bottom-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    axios({
      method: 'post',
      url:
        'https://api.exhibition.tecnodatasystem.eu/uploadProjectFromTheUser.php',
      data: toSend,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.status == 'ok') {
          // setProjectUploadedFromTheUserToken(response.data.message);
          // setFileUploadedOriginalName(response.data.originFileName);
          // setFileUploadedSize(response.data.size);
          // setStartingURL(response.data.startingURL)
          updateSingleData('fileUploadedToken', response.data.message);
          updateSingleData('startingURL', response.data.startingURL);
          updateSingleData(
            'fileUploadedOriginalName',
            response.data.originFileName
          );
          updateSingleData('fileUploadedSize', response.data.size);
          updateSingleData('isImage', response.data.isImage);
          setTimeout(() => {
            toast.dismiss();
            setTimeout(() => {
              toast.success('Caricamento completato!', {
                position: 'bottom-right',
                autoClose: 3500,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: 'colored',
              });
            }, 150);
          }, 500);
        } else {
          if (response.data.message == 'file_size_too_large') {
            updateSingleData('fileUploadedToken', '');
            updateSingleData('fileUploadedOriginalName', '');
            updateSingleData('fileUploadedSize', '');
            updateSingleData('isImage', false);
            showError('Il file che hai tentato di caricare è troppo grande.');
          }
        }
      })
      .catch(function (response) {
        //handle error
        alert('Errore nel caricamento del file.');
      });
  }

  return (
    <div style={{ marginTop: 45 }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <h3>{title}</h3>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          marginTop: 10,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <div>
          <Checkbox
            animation="jelly"
            color="danger"
            shape="curve"
            icon={<Check className="svg" data-type="svg" />}
            checked={
              steps.find((x) => x.name == thisPage).data
                .progetto
            }
            onChange={(v) => {
              updateSingleData('progetto', v.target.checked);
            }}
            style={{ marginBottom: 15 }}
          >
            Progetto
          </Checkbox>
        </div>
        <div>
          <Checkbox
            animation="jelly"
            color="danger"
            shape="curve"
            icon={<Check className="svg" data-type="svg" />}
            checked={
              steps.find((x) => x.name == thisPage).data
                .preventivo
            }
            onChange={(v) => {
              updateSingleData('preventivo', v.target.checked);
            }}
            style={{ marginBottom: 15 }}
          >
            Preventivo
          </Checkbox>
        </div>

        <div style={{ marginTop: 25, display: 'flex', justifyContent: 'left' }}>
          Data
        </div>
        <div style={{ marginBottom: 15 }}>
          <Form.Control
            type="date"
            placeholder="Disabled input"
            aria-label="Disabled input example"
            value={
              steps.find((x) => x.name == thisPage).data
                .data
            }
            onChange={(v) => {
              updateSingleData('data', v.target.value);
            }}
          />
        </div>
        <div>
          <Checkbox
            animation="jelly"
            shape="round"
            //color="danger"
            icon={<CheckCircle className="svg" data-type="svg" />}
            checked={
              steps.find((x) => x.name == thisPage).data
                .dataIndicativa
            }
            onChange={(v) => {
              if (v.target.checked) {
                updateSingleData('dataIndicativa', v.target.checked);
                updateSingleData('dataTassativa', false);
              } else {
                updateSingleData('dataIndicativa', v.target.checked);
              }
            }}
            style={{ marginBottom: 15 }}
          >
            Data indicativa
          </Checkbox>
        </div>

        <div>
          <Checkbox
            animation="jelly"
            //color="danger"
            shape="round"
            icon={<CheckCircle className="svg" data-type="svg" />}
            checked={
              steps.find((x) => x.name == thisPage).data
                .dataTassativa
            }
            onChange={(v) => {
              if (v.target.checked) {
                updateSingleData('dataTassativa', v.target.checked);
                updateSingleData('dataIndicativa', false);
              } else {
                updateSingleData('dataTassativa', v.target.checked);
              }
            }}
            style={{ marginBottom: 15 }}
          >
            Data tassativa
          </Checkbox>
        </div>

        <div style={{ marginTop: 25 }}>
          <Dropzone
            onDrop={(acceptedFiles) => uploadProjectToTheServer(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <Button variant="light">
                      <Upload className="svg" data-type="svg" /> Carica il tuo
                      progetto
                    </Button>
                  </OverlayTrigger>
                </div>
              </section>
            )}
          </Dropzone>
        </div>

        {steps.find((x) => x.name == thisPage).data
          .isImage && (
          <div
            style={{ marginTop: 15, display: 'flex', justifyContent: 'left' }}
          >
            <Col sm={8}>
              <Image
                fluid
                src={
                  steps.find((x) => x.name == thisPage)
                    .data.startingURL +
                  steps.find((x) => x.name == thisPage)
                    .data.fileUploadedToken
                }
                style={{ borderRadius: 10 }}
              ></Image>
            </Col>
          </div>
        )}

        {steps.find((x) => x.name == thisPage).data
          .fileUploadedToken != '' && (
          <div style={{ marginTop: 20 }}>
            Il file è stato caricato correttamente.
          </div>
        )}
        {steps.find((x) => x.name == thisPage).data
          .fileUploadedToken != '' && (
          <div
            style={{
              fontSize: 12,
              marginTop: 15,
              justifyContent: 'left',
              display: 'flex',
            }}
          >
            Nome file:{' '}
            {
              steps.find((x) => x.name == thisPage).data
                .fileUploadedOriginalName
            }
          </div>
        )}

        {steps.find((x) => x.name == thisPage).data
          .fileUploadedToken != '' && (
          <div
            style={{
              fontSize: 12,
              justifyContent: 'left',
              display: 'flex',
            }}
          >
            Dimensione:{' '}
            {steps
              .find((x) => x.name == thisPage)
              .data.fileUploadedSize.toFixed(2)}{' '}
            MiB
          </div>
        )}

        <div style={{ marginTop: 30 }}>
          <Button
            onMouseOver={() => {
              setIsHoveringNextButtonColor(true);
            }}
            onMouseLeave={() => {
              setIsHoveringNextButtonColor(false);
            }}
            variant="outline-light"
            onClick={() => {
              validateThisAndGoToNextPage();
            }}
          >
            <ArrowRight
              className="svg"
              color={!isHoveringNextButtonColor ? 'white' : 'black'}
              data-type="svg"
              size={30}
            />
          </Button>
        </div>
      </div>

      <ToastContainer />

      <Modal
        show={showFullPicture}
        fullscreen={false}
        size="lg"
        centered
        onHide={() => {
          setShowFullPicture(false);
        }}
      >
        <Image
          fluid
          src={
            steps.find((x) => x.name == thisPage).data
              .startingURL +
            steps.find((x) => x.name == thisPage).data
              .fileUploadedToken
          }
        ></Image>
      </Modal>
    </div>
  );
}

export default Richieste;
