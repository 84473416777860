import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Check, Upload, ArrowRight } from 'react-feather';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';

import { Maximize2, ThumbsUp, ThumbsDown } from 'react-feather';

import BootstrapSelect from 'react-bootstrap-select-dropdown';

import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';

import { useLocalStorageValue } from '@react-hookz/web'; // cjs

function Manifestazione({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [isHoveringNextButtonColor, setIsHoveringNextButtonColor] = useState(
    false
  );

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['exhibitionCurrentStep']);

  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  const yearsOptions = () => {
    var currentYear = new Date().getFullYear();
    var howManyYearsAfterCurrentYear = 5;

    var x = [];
    var startingYear = 1950;
    var endingYear = currentYear + howManyYearsAfterCurrentYear;
    for (var i = startingYear; i < endingYear; i++) {
      x.push({
        labelKey: i.toString(),
        value: i.toString(),
      });
    }

    return x;
  };

  var thisPage = 'Manifestazione';
  var title = 'Manifestazione';

  function updateSingleData(field, value) {
    var x = steps;
    x.find((x) => x.name == thisPage).data[field] = value;
    setSteps(x);
    forceUpdate();
  }

  function selectAllPictures() {
    var y = [];
    mqPictures.forEach(function (resp) {
      y.push(resp.id);
    });
    var x = steps;
    x.find((x) => x.name == thisPage).data.selectedPictures = y;
    x.find((x) => x.name == thisPage).data.likedPictures = [];
    x.find((x) => x.name == thisPage).data.dislikedPictures = [];
    setSteps(x);
  }

  function selectPicture(id) {
    var y = mqPictures;
    var c = y.findIndex((x) => x.id == id);
    //var hasToBeChecked = !y[c].checked;
    var hasToBeChecked = true; //always checked because it only allows for the pics to be checked, edit requested by Luca
    y[c].checked = hasToBeChecked;

    setMqPictures(y);
    forceUpdate();
    var x = steps.find((y) => y.name == thisPage).data.selectedPictures;
    var numerizedId = +id;

    if (hasToBeChecked) {
      if (!x.includes(numerizedId)) {
        x.push(numerizedId);
      }
    } else {
      if (x.includes(numerizedId)) {
        var c = x.findIndex((y) => y == numerizedId);
        x.splice(c, 1);
      }
    }

    var mockArr = steps;
    var index = mockArr.findIndex((x) => x.name == thisPage);
    var found = mockArr[index];
    mockArr[index].data.selectedPictures = x;
    setSteps(mockArr);
  }

  function likePicture(id) {
    var mockArr = steps;

    console.log(id);

    var liked = mockArr.find((y) => y.name == thisPage).data.likedPictures;
    if (!liked.includes(id)) {
      liked.push(id);
    }

    console.log(liked);

    var disliked = mockArr.find((y) => y.name == thisPage).data
      .dislikedPictures;
    if (disliked.includes(id)) {
      var index = disliked.indexOf(id);
      console.log(index);
      if (index > -1) {
        disliked.splice(index, 1); // 2nd parameter means remove one item only
      }
    }

    console.log(disliked);

    setSteps(mockArr);
    forceUpdate();
  }

  function dislikePicture(id) {
    var mockArr = steps;

    console.log(id);

    var disliked = mockArr.find((y) => y.name == thisPage).data
      .dislikedPictures;
    if (!disliked.includes(id)) {
      disliked.push(id);
    }

    console.log(disliked);

    var liked = mockArr.find((y) => y.name == thisPage).data.likedPictures;
    if (liked.includes(id)) {
      var index = liked.indexOf(id);
      console.log(index);
      if (index > -1) {
        liked.splice(index, 1); // 2nd parameter means remove one item only
      }
    }

    console.log(liked);

    setSteps(mockArr);
    forceUpdate();
  }

  const [misure, setMisure] = useState([]);

  const [mqPictures, setMqPictures] = useState([]);

  const [likedPictures, setLikedPictures] = useState([]);
  const [dislikedPictures, setDislikedPictures] = useState([]);

  const [showFullPicture, setShowFullPicture] = useState(false);
  const [urlCurrentPictureShown, setUrlCurrentPictureShown] = useState('');

  function showError(message) {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function validateThisAndGoToNextPage() {
    try {
      if (
        steps.find((x) => x.name == thisPage).data.manifestazione.length < 5
      ) {
        showError('Compila correttamente il campo Manifestazione');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Manifestazione');
      return;
    }

    try {
      if (steps.find((x) => x.name == thisPage).data.anno.length < 4) {
        showError('Compila correttamente il campo Anno');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Anno');
      return;
    }

    try {
      if (steps.find((x) => x.name == thisPage).data.citta.length < 2) {
        showError('Compila correttamente il campo Città');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Città');
      return;
    }

    try {
      if (steps.find((x) => x.name == thisPage).data.periodo.length < 3) {
        showError('Compila correttamente il campo Periodo');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Periodo');
      return;
    }

    try {
      if (steps.find((x) => x.name == thisPage).data.pad.length < 1) {
        showError('Compila correttamente il campo Padiglione');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Padiglione');
      return;
    }

    try {
      if (steps.find((x) => x.name == thisPage).data.post.length < 1) {
        showError('Compila correttamente il campo Postazione');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Postazione');
      return;
    }

    try {
      if (steps.find((x) => x.name == thisPage).data.mq == null) {
        showError("Seleziona un'opzione dal campo Mq.");
        return;
      }
    } catch (e) {
      console.log(e);
    }

    var $selectedPictures = steps.find((x) => x.name == thisPage).data
      .selectedPictures.length;
    var $likedPictures = steps.find((x) => x.name == thisPage).data
      .likedPictures.length;
    var $dislikedPictures = steps.find((x) => x.name == thisPage).data
      .dislikedPictures.length;

    if ($likedPictures + $dislikedPictures != $selectedPictures) {
      showError('Esprimi una preferenza per ogni stand.');
      return;
    }


    toast.loading('Validazione...', {
      position: 'bottom-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    setTimeout(() => {
      //console.log(steps);

      console.log(thisPage);
      var index = steps.findIndex((x) => x.name == thisPage);

      console.log(index);

      var mockArr = steps;
      mockArr[index].validated = true;
      mockArr[index].currentlyOpen = false;
      mockArr[index + 1].currentlyOpen = true;

      console.log(mockArr);

      setSteps(mockArr);
      setCookie('exhibitionCurrentStep', mockArr[index].id + 1, '/');

      navigate(Math.random().toString(36).substr(2, 34));

      toast.dismiss();
    }, 1000);
  }

  useEffect(() => {
    if (mqPictures.length > 0) {
      selectAllPictures();
    }
  }, [mqPictures]);

  useEffect(() => {
    retrieveMqs();
  }, []);

  useEffect(() => {
    if (misure.length > 0) {
      if (steps.find((x) => x.name == thisPage).data.mq != null) {
        setTimeout(() => {
          retrievePicsForMqs(steps.find((x) => x.name == thisPage).data.mq.id);
        }, 500);
      }
    }
  }, [misure]);

  function checkPicturesAgainstCookies() {
    var x = steps.find((y) => y.name == thisPage).data.selectedPictures;
    x.forEach(function (resp) {
      var a = mqPictures;

      var b = a.findIndex((x) => x.id === resp);
      if (b > -1) {
        a[b].checked = true;
      }
      //setMqPictures(a)
      //forceUpdate()
    });
  }

  function retrieveMqs() {
    axios
      .post('https://api.exhibition.tecnodatasystem.eu/retrieveMqs.php')
      .then(function (response) {
        if (response.data.status == 'ok') {
          setMisure(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function retrievePicsForMqs(v) {
    // var id = v.target.value;
    var id = v;
    axios
      .post(
        'https://api.exhibition.tecnodatasystem.eu/retrievePicsForMqs.php',
        {
          id: id,
        }
      )
      .then(function (response) {
        if (response.data.status == 'ok') {
          setMqPictures(response.data.data);

          console.log(response.data.data, 'as');
        } else {
          setMqPictures([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div style={{ marginTop: 45, width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <h3>{title}</h3>
      </div>

      <div style={{ marginTop: -15 }}>
        <Row className="mt-4">
          <Col
            sm={3}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Manifestazione
          </Col>
          <Col sm={5} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Manifestazione"
              value={steps.find((x) => x.name == thisPage).data.manifestazione}
              onChange={(v) => {
                updateSingleData('manifestazione', v.target.value);
              }}
            />
          </Col>
          <Col
            sm={1}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            Anno
          </Col>
          <Col sm={3} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Anno"
              value={
                steps.find((x) => x.name == thisPage).data.anno > 0
                  ? steps.find((x) => x.name == thisPage).data.anno
                  : ''
              }
              onChange={(v) => {
                updateSingleData('anno', v.target.value);
              }}
            />
            {/* <BootstrapSelect
              showSearch
              placeholder={'Anno'}
              options={yearsOptions()}
              onChange={() => {}}
              
            /> */}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col
            sm={2}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Città
          </Col>
          <Col sm={5} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Città"
              value={steps.find((x) => x.name == thisPage).data.citta}
              onChange={(v) => {
                updateSingleData('citta', v.target.value);
              }}
            />
          </Col>
          <Col
            sm={2}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            Periodo
          </Col>
          <Col sm={3} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Periodo"
              value={steps.find((x) => x.name == thisPage).data.periodo}
              onChange={(v) => {
                updateSingleData('periodo', v.target.value);
              }}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col
            sm={2}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            Padiglione
          </Col>
          <Col sm={3} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Padiglione"
              value={steps.find((x) => x.name == thisPage).data.pad}
              onChange={(v) => {
                updateSingleData('pad', v.target.value);
              }}
            />
          </Col>
          <Col
            sm={2}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            Postazione
          </Col>
          <Col sm={2} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Control
              type="text"
              placeholder="Postazione"
              value={steps.find((x) => x.name == thisPage).data.post}
              onChange={(v) => {
                updateSingleData('post', v.target.value);
              }}
            />
          </Col>
          <Col
            sm={1}
            className="mt-2"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            Mq.
          </Col>
          <Col sm={2} style={{ textAlign: 'flex-start', display: 'flex' }}>
            <Form.Select
              // aria-label="Default select example"
              value={
                steps.find((x) => x.name == thisPage).data.mq != null
                  ? steps.find((x) => x.name == thisPage).data.mq.id
                  : ''
              }
              onChange={(v) => {
                var a = steps.findIndex((x) => x.name == thisPage);
                var x = steps;

                x[a].data.selectedPictures = [];
                setSteps(x);

                retrievePicsForMqs(v.target.value);
                var check = +v.target.value;
                if (isNaN(check)) {
                  updateSingleData('mq', null);
                } else {
                  updateSingleData('mq', {
                    id: v.target.value,
                    name: v.target.value,
                  });
                }
              }}
            >
              <option>Misure</option>
              {/* <option value="1">One</option> */}
              {misure.length > 0 &&
                misure.map((element, index) => (
                  <option value={element.id} key={element.id}>
                    {element.value}
                    {' MQ '}
                  </option>
                ))}
            </Form.Select>
          </Col>

          {mqPictures.length > 0 && (
            <Col
              sm={12}
              //className="mt-4"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: 60,
              }}
            >
              <h4>Seleziona gli stand che ti piacciono di più:</h4>
            </Col>
          )}

          {mqPictures.length > 0 && (
            <Row className="mt-4" style={{ textAlign: 'left' }}>
              {mqPictures.map((element, index) => (
                <Col sm={4} className="mt-2" key={element.id}>
                  {mqPictures.find((x) => x.id == element.id).checked ? (
                    <Image
                      fluid
                      thumbnail
                      src={element.url}
                      data-id={element.id}
                      onClick={() => {
                        // selectPicture(element.id);
                      }}
                      style={{
                        filter: 'brightness(1.20)',
                        borderRadius: 10,
                        padding: 0.1,
                      }}
                    ></Image>
                  ) : (
                    <Image
                      fluid
                      src={element.url}
                      data-id={element.id}
                      onClick={() => {
                        // selectPicture(element.id);
                      }}
                      style={{
                        filter: 'brightness(0.9)',
                        borderRadius: 10,
                        padding: 0.1,
                      }}
                    ></Image>
                  )}

                  <Button
                    size="sm"
                    style={{
                      position: 'relative',
                      bottom: 100,
                      left: '30.5%',
                      paddingHorizontal: 10,
                      paddingVertical: 10,
                      opacity: steps
                        .find((x) => x.name == thisPage)
                        .data.likedPictures.includes(element.id)
                        ? 1
                        : 1,
                    }}
                    variant={
                      steps
                        .find((x) => x.name == thisPage)
                        .data.likedPictures.includes(element.id)
                        ? 'success'
                        : 'secondary'
                    }
                    onClick={() => {
                      likePicture(element.id);
                    }}
                  >
                    <ThumbsUp size={17} />
                  </Button>

                  <Button
                    size="sm"
                    style={{
                      position: 'relative',
                      bottom: 90,
                      left: '33.5%',
                      paddingHorizontal: 10,
                      paddingVertical: 10,
                      opacity: steps
                        .find((x) => x.name == thisPage)
                        .data.dislikedPictures.includes(element.id)
                        ? 1
                        : 1,
                    }}
                    variant={
                      steps
                        .find((x) => x.name == thisPage)
                        .data.dislikedPictures.includes(element.id)
                        ? 'danger'
                        : 'secondary'
                    }
                    onClick={() => {
                      dislikePicture(element.id);
                    }}
                  >
                    <ThumbsDown size={15} />
                  </Button>

                  <Button
                    size="sm"
                    style={{
                      position: 'relative',
                      bottom: 42,
                      left: '40.5%',
                      paddingHorizontal: 10,
                      paddingVertical: 10,
                    }}
                    variant="light"
                    onClick={() => {
                      setUrlCurrentPictureShown(element.url);
                      setShowFullPicture(true);
                    }}
                  >
                    <Maximize2 size={13} />
                  </Button>
                </Col>
              ))}
            </Row>
          )}
        </Row>
      </div>

      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'left' }}>
        <Button
          onMouseOver={() => {
            setIsHoveringNextButtonColor(true);
          }}
          onMouseLeave={() => {
            setIsHoveringNextButtonColor(false);
          }}
          variant="outline-light"
          onClick={() => {
            validateThisAndGoToNextPage();
          }}
        >
          <ArrowRight
            className="svg"
            color={!isHoveringNextButtonColor ? 'white' : 'black'}
            data-type="svg"
            size={30}
          />
        </Button>
      </div>

      <ToastContainer />

      <Modal
        show={showFullPicture}
        fullscreen={false}
        size="lg"
        centered
        onHide={() => {
          setShowFullPicture(false);
          setUrlCurrentPictureShown('');
        }}
      >
        <Image fluid src={urlCurrentPictureShown}></Image>
      </Modal>
    </div>
  );
}

/*

function getCookie(e){let t=e+"=",n=decodeURIComponent(document.cookie).split(";");for(let e=0;e<n.length;e++){let o=n[e];for(;" "==o.charAt(0);)o=o.substring(1);if(0==o.indexOf(t))return o.substring(t.length,o.length)}return""}JSON.parse(getCookie("exhibitionSteps"));

use this to see the cookie in the browser while you develop

*/

export default Manifestazione;
