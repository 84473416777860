import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Check, Upload, ArrowRight, Send } from 'react-feather';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import { Checkbox, Radio } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';

import { useLocalStorageValue } from '@react-hookz/web'; // cjs


import axios from 'axios'

function Note({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [isHoveringNextButtonColor, setIsHoveringNextButtonColor] = useState(
    false
  );

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['exhibitionCurrentStep']);

  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  const [currentStatusText, setCurrentStatusText] = useState('Invia la richiesta')
  const [currentStatusVariant, setCurrentStatusVariant] = useState('light')
  const [currentStatusIcon, setCurrentStatusIcon] = useState('send')

  const [hasItBeenSent, setHasItBeenSent] = useState(false)

  const [isSending, setIsSending] = useState(false)

  var thisPage = 'Note';
  var title = 'Note';

  function actuallySendFunction() {


    var x = localStorage.getItem("exhibitionSteps")
    console.log(x)

    axios.post('https://api.exhibition.tecnodatasystem.eu/actuallySendFunction.php', {
     json: x
    })
    .then(function (response) {
      var response = response.data
      if(response.status == 'ok') {

        setCurrentStatusText('Richiesta inviata!')
        setCurrentStatusVariant('success')
        setCurrentStatusIcon('sent')
        setHasItBeenSent(true)
        setIsSending(false)   
        toast.dismiss()     
      }
      else {
        setHasItBeenSent(false)
        setIsSending(false)
      }

    })
    .catch(function (error) {
      console.log(error);
    });

    // here do the axios post to actually insert it into the backend


  }

  function updateSingleData(field, value) {
    var x = steps;
    x.find((x) => x.name == thisPage).data[field] = value;
    setSteps(x);
    forceUpdate();
  }

  function showError(message) {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function showSuccess(message) {
    toast.success(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function validateThisAndGoToNextPage() {
    /* try {
      if (+steps.find((x) => x.name == thisPage).data.budget < 0.01) {
        showError('Compila correttamente il campo Budget');
        return;
      }
    } catch (e) {
      showError('Compila correttamente il campo Budget');
      return;
    }
 */


    if(hasItBeenSent) var proceed = false
    else var proceed = true

    if (!proceed) return;

    // url libero

    toast.loading('Validazione...', {
      position: 'bottom-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    setTimeout(() => {
      console.log(steps);

      setIsSending(true)
      forceUpdate()


      var index = steps.findIndex((x) => x.name == thisPage);

      var mockArr = steps;
      mockArr[index].validated = true;
      mockArr[index].currentlyOpen = false;
      //mockArr[index + 1].currentlyOpen = true;
      setSteps(mockArr);
      forceUpdate();
      //setCookie('exhibitionCurrentStep', mockArr[index].id + 1, '/');

      toast.dismiss();

      console.log(JSON.parse(localStorage.getItem('exhibitionSteps')));

     // navigate(Math.random().toString(36).substr(2, 34));



      setTimeout(() => {
        showSuccess('Invio in corso...')
        setTimeout(() => {
          actuallySendFunction()
        }, 500);
      }, 2000);

    }, 1000);
  }

  return (
    <div style={{ marginTop: 45, width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <h3>{title}</h3>
      </div>

      <div style={{ marginTop: -15 }}>
        <Row className="mt-4">
          <Col
            sm={4}
            className="mt-2"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: 600,
            }}
          >
            Note
          </Col>
          <Col
            sm={8}
            style={{
              textAlign: 'flex-start',
              display: 'flex',
              marginBottom: 10,
            }}
          >
            <Form.Control
              as="textarea"
              rows={8}
              placeholder="Note"
              value={steps.find((x) => x.name == thisPage).data.note}
              onChange={(v) => {
                updateSingleData('note', v.target.value);
              }}
            />
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'left' }}>
        {/* <Button
          onMouseOver={() => {
            setIsHoveringNextButtonColor(true);
          }}
          onMouseLeave={() => {
            setIsHoveringNextButtonColor(false);
          }}
          variant="outline-light"
          onClick={() => {
            validateThisAndGoToNextPage();
          }}
        >
          <ArrowRight
            className="svg"
            color={!isHoveringNextButtonColor ? 'white' : 'black'}
            data-type="svg"
            size={30}
          />
        </Button> */}
        <div style={{ marginTop: 50 }}>
          <Button
            variant={currentStatusVariant}
            disabled={isSending ? true : false} 
            size="sm"
            style={{
              position: 'relative',
              backgroundColor: currentStatusVariant == 'success' ? '#07bc0c' : 'white',
              cursor: hasItBeenSent ? 'default' : 'pointer',
              right: 0,
              //width: '50%',
              paddingLeft: 50,
              paddingRight: 50,

              minHeight: 40,
            }}
            onClick={() => {
              validateThisAndGoToNextPage()
            }}
          >
            {currentStatusIcon == 'send' ? <Send size={20} /> : <Check size={20} /> }
            &emsp;{currentStatusText}
          </Button>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Note;
