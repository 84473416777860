import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Check, Upload, ArrowRight } from 'react-feather';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import { Checkbox, Radio } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';

import { useLocalStorageValue } from '@react-hookz/web'; // cjs

function Send({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [isHoveringNextButtonColor, setIsHoveringNextButtonColor] = useState(
    false
  );

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['exhibitionCurrentStep']);

  const [steps, setSteps] = useLocalStorageValue(
    'exhibitionSteps',
    JSON.parse(localStorage.getItem('exhibitionSteps'))
  );

  var thisPage = 'Send';
  var title = 'Invia la richiesta';

  /* function updateSingleData(field, value) {
    var x = steps;
    x.find((x) => x.name == thisPage).data[field] = value;
    setSteps(x);
    forceUpdate();
  } */

  function showError(message) {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }
  function showSuccess(message) {
    toast.success(message, {
      position: 'bottom-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  return (
    <div style={{ marginTop: 45, width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <h3>{title}</h3>
      </div>

      <div style={{ marginTop: -15 }}>
        <Row className="mt-4">
          <Button
            variant="success"
            size="sm"
            style={{
              position: 'relative',
              right: 0,
              width: '50%',
              minHeight: 30,
            }}
          >
            Invia la richiesta
          </Button>
        </Row>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Send;
